import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Button, Glyphicon,
  Row, Col, Panel,
  FormGroup, InputGroup, FormControl,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { SET_MATCHING_TABLE } from '../../reducers/user';

const KEYS = ['minus1', 'zero', 'plus1', 'plus2', 'plus3', 'plus4', 'plus5'];


class MatchingTableSettingPanel extends React.Component {
  constructor(props) {
    super(props);
    const { matchingTable } = this.props;
    this.state = {
      savingInprogress: false,
      thereAreChanges: false,
      matchingTableLocal: matchingTable,
    };
  }

  handleChange(score, newValue) {
    const { matchingTableLocal } = this.state;
    this.setState({
      thereAreChanges: true,
      matchingTableLocal: [
        ...matchingTableLocal.slice(0, score),
        newValue,
        ...matchingTableLocal.slice(score + 1),
      ],
    });
  }

  handleSave() {
    const { userRef, setMatchingTable } = this.props;
    const { thereAreChanges, matchingTableLocal } = this.state;
    if (thereAreChanges) {
      this.setState({ savingInprogress: true });
      userRef.update({ matchingTable: matchingTableLocal })
        .then(() => {
          this.setState({
            savingInprogress: false,
            thereAreChanges: false,
          });
          setMatchingTable(matchingTableLocal);
        });
    }
  }

  render() {
    const { savingInprogress, matchingTableLocal } = this.state;
    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title>
            <FormattedMessage
              id="setting.matchingtable.header"
              defaultMessage="Matching table"
            />
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup>
                {matchingTableLocal.map((grade, score) => (
                  <InputGroup key={KEYS[score]}>
                    <InputGroup.Addon style={{ width: '50%' }}>
                      <FormattedMessage
                        id="setting.matchingtable.willbematched"
                        defaultMessage={'Score {score} will be matched to'}
                        values={{ score: <b>{score - 1}</b> }}
                      />
                    </InputGroup.Addon>

                    <FormControl
                      type="text"
                      maxLength="40"
                      value={grade}
                      onChange={event => this.handleChange(score, event.target.value)}
                    />
                    <InputGroup.Addon>
                    </InputGroup.Addon>
                  </InputGroup>
                ))}
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <h4>
                <FormattedMessage
                  id="setting.matchingtable.description.header"
                  defaultMessage="Here is your matching table"
                />
              </h4>
              <p>
                <FormattedMessage
                  id="setting.matchingtable.description.paragraph1"
                  defaultMessage="You can select how to match students lesson
                  scores to your regular school marks."
                />
              </p>
              <p>
                <FormattedMessage
                  id="setting.matchingtable.description.paragraph2"
                  defaultMessage="Edit the matching table any way you like and click the 'save' button.
                  You can use text, numbers and even emojis here. Limit is 40 characters."
                />
              </p>
              <Button
                bsStyle="primary"
                onClick={() => this.handleSave()}
                disabled={savingInprogress}
              >
                {
                  savingInprogress
                    ? (
                      <div>
                        <FormattedMessage
                          id="setting.matchingtable.saving"
                          defaultMessage="Saving..."
                        />
                      </div>
                    )
                    : (
                      <div>
                        <Glyphicon glyph="ok" />
                    &nbsp;
                        <FormattedMessage
                          id="setting.matchingtable.save"
                          defaultMessage="Save"
                        />
                      </div>
                    )
                }
              </Button>
            </Col>
          </Row>
        </Panel.Body>
      </Panel>
    );
  }
}

MatchingTableSettingPanel.propTypes = {
  userRef: PropTypes.shape({}).isRequired,
  matchingTable: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  setMatchingTable: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  userRef: state.user.ref,
  matchingTable: state.user.matchingTable,
});

const mapDispatchToProps = dispatch => ({
  setMatchingTable: matchingTable => dispatch({
    type: SET_MATCHING_TABLE,
    payload: { matchingTable },
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MatchingTableSettingPanel);
