import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Table, Glyphicon, Grid, Row, Col,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';


class LessonFinishedView extends React.Component {
  state = {
    showAbsent: false,
  };

  render() {
    const { showAbsent } = this.state;
    const { students, goBack, matchingTable } = this.props;

    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <Table striped bordered condensed hover>
              <thead>
                <tr>
                  <th style={{ width: '60%' }}>
                    <FormattedMessage
                      id="lesson.finished.table.name"
                      defaultMessage="Name"
                    />
                  </th>
                  <th style={{ width: '20%' }}>
                    <FormattedMessage
                      id="lesson.finished.table.score"
                      defaultMessage="Score"
                    />
                  </th>
                  <th style={{ width: '20%' }}>
                    <FormattedMessage
                      id="lesson.finished.table.grade"
                      defaultMessage="Grade"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {students
                  .filter(student => showAbsent || !student.absent)
                  .map(student => (
                    <tr key={student.id}>
                      <td>
                        {
                        (student.score === 5)
                          ? (
                            <b>
                              {student.name}
                              {' '}
                              <Glyphicon glyph="star" />
                            </b>
                          )
                          : student.name
                      }
                      </td>
                      <td>
                        {
                        student.absent
                          ? (
                            <FormattedMessage
                              id="lesson.finished.table.absent"
                              defaultMessage="Absent"
                            />
                          )
                          : student.score
                      }
                      </td>
                      <td>
                        {
                        student.absent
                          ? (
                            <FormattedMessage
                              id="lesson.finished.table.absent"
                              defaultMessage="Absent"
                            />
                          )
                          : matchingTable[student.score + 1]
                      }
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row style={{ marginTop: '20px' }}>
          <Col xs={12}>
            <Button onClick={() => this.setState({ showAbsent: !showAbsent })}>
              {
                showAbsent
                  ? <FormattedMessage id="lesson.finished.hideabsent" defaultMessage="Hide Absent" />
                  : <FormattedMessage id="lesson.finished.showabsent" defaultMessage="Show Absent" />
              }
            </Button>
            <Button onClick={() => goBack()} bsStyle="primary" className="pull-right">
              <FormattedMessage id="lesson.finished.done" defaultMessage="Done" />
            </Button>
          </Col>
        </Row>

      </Grid>
    );
  }
}

LessonFinishedView.propTypes = {
  goBack: PropTypes.func.isRequired,
  students: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    absent: PropTypes.bool.isRequired,
  })).isRequired,
  matchingTable: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};


const mapStateToProps = state => ({
  matchingTable: state.user.matchingTable,
});

export default connect(mapStateToProps, null)(LessonFinishedView);
