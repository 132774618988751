import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Glyphicon,
  Form, FormGroup, InputGroup, FormControl, ControlLabel,
} from 'react-bootstrap';


class NewItemForm extends React.Component {
  state = { value: '' };

  onChange = event => this.setState({ value: event.target.value });

  onClick = () => {
    const { value } = this.state;
    const { onAdd } = this.props;
    onAdd(value);
    this.setState({ value: '' });
  };

  render() {
    const { value } = this.state;
    const { label, placeholder } = this.props;
    return (
      <Form onSubmit={(event) => { event.preventDefault(); this.onClick(); }}>
        <FormGroup>
          <ControlLabel>{label || 'Add new'}</ControlLabel>
          <InputGroup>
            <FormControl
              type="text"
              maxLength="140"
              placeholder={placeholder || ''}
              value={value}
              onChange={this.onChange}
            />
            <InputGroup.Button>
              <Button onClick={this.onClick} disabled={!value}>
                <Glyphicon glyph="plus" />
              </Button>
            </InputGroup.Button>
          </InputGroup>
        </FormGroup>
      </Form>
    );
  }
}

NewItemForm.defaultProps = {
  placeholder: '',
};

NewItemForm.propTypes = {
  onAdd: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
};


export default NewItemForm;
