import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/functions';


const config = {
  apiKey: 'AIzaSyDWM-JXNNx8RztglqcFnN0JvZsI-z_k9Vk',
  authDomain: 'zebra-classroom.firebaseapp.com',
  databaseURL: 'https://zebra-classroom.firebaseio.com',
  projectId: 'zebra-classroom',
  storageBucket: 'zebra-classroom.appspot.com',
  messagingSenderId: '66007006360',
};

firebase.initializeApp(config);


export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();

export const firebaseAuth = firebase.auth();
export const signInOptions = [
  firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  firebase.auth.EmailAuthProvider.PROVIDER_ID,
];
