import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { ButtonGroup, DropdownButton, MenuItem } from 'react-bootstrap';
import { SWITCH_SELECTED_THEME } from '../../reducers/themes';


const ThemeDropdownSelector = ({ themesNames, changeTheme }) => (
  <ButtonGroup block vertical>
    <DropdownButton
      title={(
        <FormattedMessage
          id="lesson.inprogress.themeselector"
          defaultMessage="Theme"
        />
      )}
      id="theme-selector"
      onSelect={changeTheme}
    >
      {themesNames.map(name => (
        <MenuItem key={name} eventKey={name}>
          <FormattedMessage
            id={`themes.${name}`}
            defaultMessage={name}
          />
        </MenuItem>
      ))}
    </DropdownButton>
  </ButtonGroup>
);

ThemeDropdownSelector.propTypes = {
  themesNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  changeTheme: PropTypes.func.isRequired,
  // locale: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  themesNames: state.themes.availableThemes.map(theme => theme.name),
  // locale: state.intl.locale,
});

const mapDispatchToProps = dispatch => ({
  changeTheme: name => {
    window.mixpanel.track('Theme Changed', { 'Theme Name': name });
    dispatch({ type: SWITCH_SELECTED_THEME, name })
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeDropdownSelector);
