import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button, ButtonGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import FontAwesome from 'react-fontawesome';
import { splitArrayIntoNGroups } from './GroupWork';


class GroupWorkLocal extends React.PureComponent {
  splitStudentsToGroups = (n) => {
    const { students, setupGroups, hideModal } = this.props;
    const split = splitArrayIntoNGroups(students, n);
    setupGroups(split);
    hideModal();
  }

  killGroups() {
    const { finishGroupWork } = this.props;
    finishGroupWork();
  }

  render() {
    const {
      groupModeIsOn, showModal, hideModal, modalIsOpen,
    } = this.props;

    const noGroupsButton = (
      <Button block onClick={showModal}>
        <FontAwesome name="users" size="lg" style={{ marginRight: '5px', color: '#555555' }} />
        <FormattedMessage
          id="lesson.inprogress.groups.start"
          defaultMessage="Group work"
        />
      </Button>
    );

    const groupsButton = (
      <Button block onClick={() => this.killGroups()}>
        <FontAwesome name="users" size="lg" style={{ marginRight: '5px', color: '#555555' }} />

        <FormattedMessage
          id="lesson.inprogress.groups.end"
          defaultMessage="End group work"
        />
      </Button>
    );

    return (

      <div>
        {
          groupModeIsOn ? groupsButton : noGroupsButton
        }


        <Modal
          show={modalIsOpen}
          onHide={hideModal}
          animation
        >

          <Modal.Header className="text-center" style={{ height: '100px' }}>
            <h1>
              <FormattedMessage
                id="lesson.inprogress.groups.modal.header"
                defaultMessage="Group work mode"
              />
            </h1>
          </Modal.Header>

          <Modal.Body className="text-center">
            <h3>
              <FormattedMessage
                id="lesson.inprogress.groups.modal.select"
                defaultMessage="Number of groups"
              />
            </h3>
            <ButtonGroup bsSize="large">
              <Button onClick={() => this.splitStudentsToGroups(2)}>
                2
              </Button>
              <Button onClick={() => this.splitStudentsToGroups(3)}>
                3
              </Button>
              <Button onClick={() => this.splitStudentsToGroups(4)}>
                4
              </Button>
              <Button onClick={() => this.splitStudentsToGroups(5)}>
                5
              </Button>
              <Button onClick={() => this.splitStudentsToGroups(6)}>
                6
              </Button>
            </ButtonGroup>

            <p />

            <Button onClick={hideModal} bsStyle="info">
              <FormattedMessage
                id="custom.lesson.inprogress.random.close"
                defaultMessage="Close"
              />
            </Button>

          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

GroupWorkLocal.propTypes = {
  students: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,

  modalIsOpen: PropTypes.bool.isRequired,
  groupModeIsOn: PropTypes.bool.isRequired,

  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  setupGroups: PropTypes.func.isRequired,
  finishGroupWork: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  modalIsOpen: state.groups.modalIsOpen,
  groupModeIsOn: state.groups.groups.length > 0,
});

const mapDispatchToProps = dispatch => ({
  showModal: () => dispatch({ type: 'SHOW_MODAL' }),
  hideModal: () => dispatch({ type: 'HIDE_MODAL' }),
  setupGroups: groups => dispatch({ type: 'SETUP_GROUPS', groups }),
  finishGroupWork: () => dispatch({ type: 'FINISH_GROUP_WORK' }),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupWorkLocal);
