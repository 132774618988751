import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Grid, Row, Col, Image, Button, Glyphicon,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import FontAwesome from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import screenshot from '../images/ZC.jpg';


const HeaderRow = () => (
  <Row style={{ paddingTop: '80px', paddingBottom: '80px' }}>
    <Col xs={12} className="text-center">
      <h1 style={{ fontSize: '72px' }}>
        <FormattedMessage
          id="home.title"
          defaultMessage="Zebra Classroom"
        />
      </h1>
      <h2>
        <FormattedMessage
          id="home.subtitle"
          defaultMessage="Simple real-time assessment tool for a modern classroom. Free for teachers"
        />
      </h2>
    </Col>
  </Row>
);

const ImageRow = () => (
  <Row>
    <Col xs={12}>
      <Image src={screenshot} style={{ width: '100%' }} />
    </Col>
  </Row>
);

const HowItWorksRow = () => (
  <div>
    <Row style={{ paddingTop: '40px' }}>
      <Col xs={12}>
        <h1 style={{ fontSize: '50px' }} className="text-center">
          <FormattedMessage
            id="home.howitworks.header"
            defaultMessage="How it works"
          />
        </h1>
      </Col>
    </Row>

    <Row style={{ paddingTop: '20px', paddingBottom: '80px', fontSize: '1.3em' }}>
      <Col xs={12} mdOffset={2} md={8}>

        <ol style={{ lineHeight: '1.5' }}>
          <li>
            <FormattedMessage
              id="home.howitworks.create"
              defaultMessage="Create a list of students"
            />
          </li>
          <li>
            <FormattedMessage
              id="home.howitworks.start"
              defaultMessage="Start the lesson"
            />
          </li>
          <li>
            <FormattedMessage
              id="home.howitworks.add"
              defaultMessage="Push green buttons for every correct answer"
            />
          </li>
          <li>
            <FormattedMessage
              id="home.howitworks.deduct"
              defaultMessage="Deduct points for misbehaviour with red buttons"
            />
          </li>
          <li>
            <FormattedMessage
              id="home.howitworks.sync"
              defaultMessage="Push buttons on your smartphone and see the changes on all synced devices"
            />
          </li>
          <li>
            <FormattedMessage
              id="home.howitworks.engage"
              defaultMessage="Engage students to work actively with the magic of real-time feedback"
            />
          </li>
        </ol>
      </Col>
    </Row>
  </div>
);

const FeaturesRow = () => (
  <div style={{ fontSize: '1.3em', marginTop: '80px' }}>
    <Row style={{ paddingTop: '20px', marginBottom: '40px' }}>
      <Col xs={12}>
        <h1 style={{ fontSize: '50px' }} className="text-center">
          <FormattedMessage
            id="home.features.header"
            defaultMessage="Why Zebra Classroom"
          />
        </h1>
      </Col>
    </Row>
    <Row className="text-center" style={{ paddingTop: '10px', paddingBottom: '10px' }}>
      <Col xs={12} md={4}>
        <h1><FontAwesome name="dragon" /></h1>
        <h3>
          <FormattedMessage
            id="home.features.dynamic.header"
            defaultMessage="Dynamic"
          />
        </h3>
        <p>
          <FormattedMessage
            id="home.features.dynamic.text"
            defaultMessage="It’s like your usual lesson but on steroids.
            Set up a clear goal and give students feedback instantly in the realtime.
            Funny badges are waiting for everyone and there is no time to waste."
          />
        </p>
      </Col>
      <Col xs={12} md={4}>
        <h1><FontAwesome name="anchor" /></h1>
        <h3>
          <FormattedMessage
            id="home.features.engaging.header"
            defaultMessage="Engaging"
          />
        </h3>
        <p>
          <FormattedMessage
            id="home.features.engaging.text"
            defaultMessage="Change a focus from asking few students or
            being a speaking head to learner-centered tasks and close monitoring.
            Active presence is required and everybody can participate."
          />
        </p>
      </Col>
      <Col xs={12} md={4}>
        <h1><FontAwesome name="bomb" /></h1>
        <h3>
          <FormattedMessage
            id="home.features.challenging.header"
            defaultMessage="Challenging"
          />
        </h3>
        <p>
          <FormattedMessage
            id="home.features.challenging.text"
            defaultMessage="Challenge your students with the powerful flow of tasks.
            Challenge yourself to design lessons carefully and
            to check all the work your class is accomplishing."
          />
        </p>
      </Col>
    </Row>

    <Row className="text-center" style={{ paddingTop: '30px', paddingBottom: '100px' }}>
      <Col xs={12} md={4}>
        <h1><FontAwesome name="cubes" /></h1>
        <h3>
          <FormattedMessage
            id="home.features.modular.header"
            defaultMessage="Modular"
          />
        </h3>
        <p>
          <FormattedMessage
            id="home.features.modular.text"
            defaultMessage="Build your lesson from multiple small tasks:
            different learning types, different levels of difficulty.
            There is no “high-stakes” activities and it becomes clear what the grade comes from."
          />
        </p>
      </Col>
      <Col xs={12} md={4}>
        <h1><FontAwesome name="gamepad" /></h1>
        <h3>
          <FormattedMessage
            id="home.features.gamified.header"
            defaultMessage="Gamified"
          />
        </h3>
        <p>
          <FormattedMessage
            id="home.features.gamified.text"
            defaultMessage="It’s not just yet another lesson. It’s a journey. It’s a fight.
            It’s a process of evolution. Dive into the universe of Star Wars or win a supercar.
            Create a special mood for your lesson in one click by changing the theme."
          />
        </p>
      </Col>
      <Col xs={12} md={4}>
        <h1><FontAwesome name="cloud" /></h1>
        <h3>
          <FormattedMessage
            id="home.features.modern.header"
            defaultMessage="Modern"
          />
        </h3>
        <p>
          <FormattedMessage
            id="home.features.modern.text"
            defaultMessage="Impress your students with the real-time synchronization.
            Use your smartphone to change points and students will immediately
            see the changes on the big screen."
          />
        </p>
      </Col>
    </Row>
  </div>
);

const ReadyRow = ({ isLoggedIn }) => (
  <div>
    <Row className="text-center" style={{ marginBottom: '30px' }}>
      <Col xs={12}>
        <h1 style={{ fontSize: '50px' }}>
          <FormattedMessage
            id="home.ready.header"
            defaultMessage="Ready?"
          />
        </h1>
      </Col>
    </Row>

    <Row className="text-center" style={{ marginBottom: '120px' }}>
      <Col md={4} xs={12}>
        <LinkContainer to="/login" style={{ width: '90%' }}>
          <Button bsStyle="primary" bsSize="large">
            <span style={{ fontSize: 24 }}>
              <FormattedMessage
                id="home.ready.signin"
                defaultMessage="Create an account"
              />
            </span>
          </Button>
        </LinkContainer>
      </Col>
      <Col md={4} xs={12}>
        <LinkContainer to="/lesson" style={{ width: '90%' }}>
          <Button bsStyle="success" bsSize="large">
            <span style={{ fontSize: 24 }}>
              {
                isLoggedIn
                  ? (
                    <FormattedMessage
                      id="home.tryit.button.loggedin"
                      defaultMessage="Start a lesson"
                    />
                  )
                  : (
                    <FormattedMessage
                      id="home.tryit.button.loggedout"
                      defaultMessage="Try it out in demo mode"
                    />
                  )
              }
            </span>
          </Button>
        </LinkContainer>
      </Col>
      <Col md={4} xs={12}>
        <LinkContainer to="/faq" style={{ width: '90%' }}>
          <Button bsStyle="info" bsSize="large">
            <span style={{ fontSize: 24 }}>
              <FormattedMessage
                id="home.ready.faq"
                defaultMessage="Read more at FAQ"
              />
            </span>
          </Button>
        </LinkContainer>
      </Col>
    </Row>
  </div>
);

ReadyRow.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

const TryItOutRow = ({ isLoggedIn }) => (
  <Row className="text-center" style={{ marginTop: '10rem', marginBottom: '3rem' }}>
    <Col xs={12} mdOffset={3} md={6}>
      <LinkContainer to="/lesson" style={{ width: '100%' }}>
        <Button bsStyle="success" bsSize="large">
          <span style={{ fontSize: 24 }}>
            {
              isLoggedIn
                ? (
                  <FormattedMessage
                    id="home.tryit.button.loggedin"
                    defaultMessage="Start a lesson"
                  />
                )
                : (
                  <FormattedMessage
                    id="home.tryit.button.loggedout"
                    defaultMessage="Try it out in demo mode"
                  />
                )
            }
          </span>
        </Button>
      </LinkContainer>
    </Col>
  </Row>
);

TryItOutRow.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

const TeacherToolkitRow = () => (
  <div>
    <Row style={{ paddingTop: '20px' }}>
      <Col xs={12}>
        <h1 style={{ fontSize: '50px' }} className="text-center">
          <FormattedMessage
            id="home.toolkit.header"
            defaultMessage="Basic teacher toolkit"
          />
        </h1>
      </Col>
    </Row>
    <Row className="text-center" style={{ fontSize: '1.3em', paddingTop: '20px' }}>
      <Col xs={12} md={3}>
        <h1><FontAwesome name="users" /></h1>
        <h3>
          <FormattedMessage
            id="home.toolkit.tool.groups"
            defaultMessage="Group work"
          />
        </h3>
      </Col>
      <Col xs={12} md={3}>
        <h1><Glyphicon glyph="hourglass" /></h1>
        <h3>
          <FormattedMessage
            id="home.toolkit.tool.timer"
            defaultMessage="Timer"
          />
        </h3>
      </Col>
      <Col xs={12} md={3}>
        <h1><FontAwesome name="random" /></h1>
        <h3>
          <FormattedMessage
            id="home.toolkit.tool.selector"
            defaultMessage="Random student"
          />
        </h3>
      </Col>
      <Col xs={12} md={3}>
        <h1><FontAwesome name="palette" /></h1>
        <h3>
          <FormattedMessage
            id="home.toolkit.tool.themes"
            defaultMessage="Choice of themese"
          />
        </h3>
      </Col>
    </Row>
  </div>
);

const Home = ({ isLoggedIn }) => (
  <div>
    <Helmet>
      <title>Zebra Classroom - Gamified assessment tool</title>
    </Helmet>
    <Grid>
      <HeaderRow />
      <ImageRow />
      <TryItOutRow isLoggedIn={isLoggedIn} />
      <HowItWorksRow />
      <TeacherToolkitRow />
      <FeaturesRow />
      <ReadyRow isLoggedIn={isLoggedIn} />
    </Grid>
  </div>
);

Home.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isLoggedIn: state.user.ref !== null,
});


export default connect(mapStateToProps, null)(Home);
