import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Row, Col,
  Image, Button, ButtonGroup, Glyphicon, Badge,
  ToggleButtonGroup, ToggleButton,
} from 'react-bootstrap';
import { Confirm } from 'react-confirm-bootstrap';
import windowSize from 'react-window-size';
import { FormattedMessage } from 'react-intl';
import RandomStudentSelector from './RandomStudentSelector';
import LessonTimer from './LessonTimer';
import GroupWork from './GroupWork';
import ThemeDropdownSelector from './ThemeDropdownSelector';
import { themeShape } from '../../reducers/themes';

const rowStyle = {
  display: 'flex',
  alignItems: 'center',
  height: '70px',
};

export const imageStyle = {
  maxHeight: '60px',
  maxWidth: '100%',
};

export const groupColors = [
  '#2c427c',
  '#caa336',
  '#909fcd',
  '#c23d25',
  '#31735e',
  '#f19070',
];

export const Circle = ({ color, size }) => (
  <div style={{
    width: `${size}px`,
    height: `${size}px`,
    background: color,
    MozBorderRadius: `${size / 2}px`,
    WebkitBorderRadius: `${size / 2}px`,
    borderRadius: `${size / 2}px`,
  }}
  />
);

Circle.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};


export const decideOnColor = (index, condensedLayout) => {
  if (condensedLayout) {
    return Math.floor(index / 4) % 2 === 1 ? '#f0f0f0' : '#ffffff';
  }
  return (index + 1) % 2 === 1 ? '#f0f0f0' : '#ffffff';
};

export const getGroupColor = (id, groups) => (groups.length > 0
  ? groupColors[groups.findIndex(list => list.includes(id))]
  : undefined);

export const ImageMinus1 = ({ theme: { rounded, circle, minus1 } }) => (
  <Image style={imageStyle} rounded={rounded} circle={circle} src={minus1} />
);
ImageMinus1.propTypes = { theme: PropTypes.shape(themeShape).isRequired };

export const ImagePlus1 = ({ theme: { rounded, circle, plus1 } }) => (
  <Image style={imageStyle} rounded={rounded} circle={circle} src={plus1} />
);
ImagePlus1.propTypes = { theme: PropTypes.shape(themeShape).isRequired };

export const ImagePlus2 = ({ theme: { rounded, circle, plus2 } }) => (
  <Image style={imageStyle} rounded={rounded} circle={circle} src={plus2} />
);
ImagePlus2.propTypes = { theme: PropTypes.shape(themeShape).isRequired };

export const ImagePlus3 = ({ theme: { rounded, circle, plus3 } }) => (
  <Image style={imageStyle} rounded={rounded} circle={circle} src={plus3} />
);
ImagePlus3.propTypes = { theme: PropTypes.shape(themeShape).isRequired };

export const ImagePlus4 = ({ theme: { rounded, circle, plus4 } }) => (
  <Image style={imageStyle} rounded={rounded} circle={circle} src={plus4} />
);
ImagePlus4.propTypes = { theme: PropTypes.shape(themeShape).isRequired };

export const ImagePlus5 = ({ theme: { rounded, circle, plus5 } }) => (
  <Image style={imageStyle} rounded={rounded} circle={circle} src={plus5} />
);
ImagePlus5.propTypes = { theme: PropTypes.shape(themeShape).isRequired };


export const selectSingleImage = (score, theme) => {
  if (score === -1) {
    return <ImageMinus1 theme={theme} />;
  }
  const images = [
    <ImagePlus1 theme={theme} />,
    <ImagePlus2 theme={theme} />,
    <ImagePlus3 theme={theme} />,
    <ImagePlus4 theme={theme} />,
    <ImagePlus5 theme={theme} />,
  ];
  return images[score - 1];
};


export const StudentRowDesktopCondensed = ({
  score, name, changeStudentScore, theme, backgroundColor, groupColor,
}) => {
  const style = {
    paddingBottom: '6px',
    paddingTop: '6px',
    backgroundColor,
    height: '135px',
  };

  return (
    <Col className="text-center" xs={3} style={style}>
      {
        (score === 0)
          ? (
            <span style={{ display: 'block', height: '40px' }}>
              <ButtonGroup style={{ marginTop: '20px' }}>
                <Button bsSize="xs" onClick={() => changeStudentScore(-1)}>
                  <Glyphicon glyph="minus" />
                </Button>
                <Button bsSize="xs" onClick={() => changeStudentScore(+1)}>
                  <Glyphicon glyph="plus" />
                </Button>
              </ButtonGroup>
            </span>
          )
          : (
            <span>
              <Button bsSize="xs" style={{ marginRight: '15px' }} onClick={() => changeStudentScore(-1)}>
                <Glyphicon glyph="minus" />
              </Button>
              {selectSingleImage(score, theme)}
              <Button bsSize="xs" style={{ marginLeft: '15px' }} onClick={() => changeStudentScore(+1)}>
                <Glyphicon glyph="plus" />
              </Button>
            </span>
          )
      }

      <br />

      <div style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        padding: '5px',
      }}
      >
        {groupColor && <Circle color={groupColor} size={25} />}
        <div style={{ width: '90%', fontSize: '22px' }}>
          {
            score === 5
              ? (
                <b>
                  {name}
                  {' '}
                  <Glyphicon glyph="star" />
                </b>
              )
              : name
          }
        </div>
      </div>
    </Col>
  );
};

StudentRowDesktopCondensed.defaultProps = {
  groupColor: undefined,
};

StudentRowDesktopCondensed.propTypes = {
  score: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  changeStudentScore: PropTypes.func.isRequired,
  theme: PropTypes.shape(themeShape).isRequired,
  backgroundColor: PropTypes.string.isRequired,
  groupColor: PropTypes.string,
};


export const StudentRowDesktop = ({
  score, name, changeStudentScore, theme, backgroundColor, groupColor,
}) => {
  const style = {
    ...rowStyle,
    backgroundColor,
  };

  return (
    <Row className="text-center" style={style}>
      <Col className="text-center" xs={1}>
        {(score < 0) && <ImageMinus1 theme={theme} />}
      </Col>
      <Col xs={1}>
        <Button bsStyle="danger" onClick={() => changeStudentScore(-1)}>
          <Glyphicon glyph="minus" />
        </Button>
      </Col>
      <Col xs={4} style={{ display: 'flex' }}>
        {groupColor && <Circle color={groupColor} size={35} />}
        <div style={{ width: '90%', fontSize: '22px' }}>
          {
            score === 5
              ? (
                <b>
                  {name}
                  {' '}
                  <Glyphicon glyph="star" />
                </b>
              )
              : name
          }
        </div>
      </Col>
      <Col xs={1}>
        <Button bsStyle="success" onClick={() => changeStudentScore(+1)}>
          <Glyphicon glyph="plus" />
        </Button>
      </Col>
      <Col xs={1}>
        {(score >= 1) && <ImagePlus1 theme={theme} />}
      </Col>
      <Col xs={1}>
        {(score >= 2) && <ImagePlus2 theme={theme} />}
      </Col>
      <Col xs={1}>
        {(score >= 3) && <ImagePlus3 theme={theme} />}
      </Col>
      <Col xs={1}>
        {(score >= 4) && <ImagePlus4 theme={theme} />}
      </Col>
      <Col xs={1}>
        {(score >= 5) && <ImagePlus5 theme={theme} />}
      </Col>
    </Row>
  );
};

StudentRowDesktop.defaultProps = {
  groupColor: undefined,
};

StudentRowDesktop.propTypes = {
  score: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  changeStudentScore: PropTypes.func.isRequired,
  theme: PropTypes.shape(themeShape).isRequired,
  backgroundColor: PropTypes.string.isRequired,
  groupColor: PropTypes.string,
};

export const CondensedLayoutToggleButton = ({ handleChangeLayout, condensedLayout }) => (
  <ToggleButtonGroup block vertical type="checkbox" onChange={handleChangeLayout}>
    <ToggleButton value={1}>
      {
        condensedLayout
          ? (
            <span>
              <Glyphicon glyph="align-justify" style={{ marginRight: '5px' }} />
              <FormattedMessage
                id="lesson.inprogress.expand"
                defaultMessage="Expand"
              />
            </span>
          )
          : (
            <span>
              <Glyphicon glyph="th" style={{ marginRight: '5px' }} />
              <FormattedMessage
                id="lesson.inprogress.collapse"
                defaultMessage="Collapse"
              />
            </span>
          )
      }
    </ToggleButton>
  </ToggleButtonGroup>
);

CondensedLayoutToggleButton.propTypes = {
  handleChangeLayout: PropTypes.func.isRequired,
  condensedLayout: PropTypes.bool.isRequired,
};


export const StudentRowMobile = ({
  score, name, changeStudentScore, groupColor,
}) => (
    <Row className="text-center" style={rowStyle}>
      <Col xs={2} style={{ paddingRight: '5px', paddingLeft: '5px' }}>
        <Button bsSize="small" bsStyle="danger" onClick={() => changeStudentScore(-1)}>
          <Glyphicon glyph="minus" />
        </Button>
      </Col>
      <Col xs={8} style={{ paddingRight: '5px', paddingLeft: '5px', display: 'flex' }}>
        {groupColor && <Circle color={groupColor} size={35} />}
        <div style={{ width: '100%', fontSize: '22px' }}>
          {
            score === 5
              ? (
                <b>
                  {name}
                  {' '}
                  <Glyphicon glyph="star" />
                </b>
              )
              : name
          }
        </div>

      </Col>
      <Badge><span style={{ fontSize: '18px' }}>{score}</span></Badge>


      <Col xs={2} style={{ paddingRight: '5px', paddingLeft: '5px' }}>
        <Button bsSize="small" bsStyle="success" onClick={() => changeStudentScore(+1)}>
          <Glyphicon glyph="plus" />
        </Button>
      </Col>
    </Row>
  );

StudentRowMobile.defaultProps = {
  groupColor: undefined,
};

StudentRowMobile.propTypes = {
  score: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  changeStudentScore: PropTypes.func.isRequired,
  groupColor: PropTypes.string,
};


class LessonInProgressView extends React.Component {
  state = {
    condensedLayout: false,
  }

  handleChangeLayout = () => {
    this.setState(prevState => ({ condensedLayout: !prevState.condensedLayout }));
  };

  plusOneForEveyone = () => {
    window.mixpanel.track('Plus One For Everyone');
    const { batchChangeStudentScore, students } = this.props;
    const ids = students.map(student => student.id);
    batchChangeStudentScore(ids, +1);
  };

  changeGroupScore = (group, change) => {
    const { batchChangeStudentScore, students } = this.props;
    const ids = students
      .filter(student => group.includes(student.id))
      .map(student => student.id);

    batchChangeStudentScore(ids, change);
  };

  render() {
    const { condensedLayout } = this.state;
    const {
      finishLesson, changeStudentScore, students, groups, windowWidth, selectedTheme,
    } = this.props;

    // TODO: renders twice for some reason on random seleteor for example

    const FinishConfirmWindow = (
      <Confirm
        body={(
          <FormattedMessage
            id="lesson.inprogress.finish.text"
            defaultMessage="Are you sure you want to finish the lesson?"
          />
        )}
        confirmText={(
          <FormattedMessage
            id="lesson.inprogress.finish.confirmText"
            defaultMessage="Confirm"
          />
        )}
        title={(
          <FormattedMessage
            id="lesson.inprogress.finish.title"
            defaultMessage="Finishing the lesson"
          />
        )}
        cancelText={(
          <FormattedMessage
            id="confirm.cancel"
            defaultMessage="Cancel"
          />
        )}
        onConfirm={finishLesson}
      >
        <Button block bsStyle="primary">
          <FormattedMessage
            id="lesson.inprogress.finish.button"
            defaultMessage="Finish"
          />
        </Button>
      </Confirm>
    );

    const selector = (
      <RandomStudentSelector
        changeStudentScore={changeStudentScore}
        students={students}
      />
    );

    const topRow = (
      <Row style={{ paddingBottom: '20px' }} className="text-center">
        <Col xs={2}>
          <ThemeDropdownSelector />
        </Col>

        <Col xs={2}>
          <CondensedLayoutToggleButton
            handleChangeLayout={this.handleChangeLayout}
            condensedLayout={condensedLayout}
          />
        </Col>

        <Col xs={2}>
          <LessonTimer />
        </Col>

        <Col xs={2}>
          {selector}
        </Col>

        <Col xs={2} className="text-center">
          <Button onClick={this.plusOneForEveyone} block>
            <FormattedMessage
              id="lesson.inprogress.plusone"
              defaultMessage="+1 for everyone"
            />
          </Button>
        </Col>

        <Col xs={2}>
          <GroupWork
            students={students.map(student => student.id)}
          />
        </Col>
      </Row>
    );

    const buttonBottomRowMobile = (
      <div>
        <Row style={{ paddingTop: '40px' }}>
          <Col xs={12}>
            {selector}
          </Col>
        </Row>
        <Row style={{ paddingTop: '5px' }}>
          <Col xs={12}>
            <Button block className="pull-right" onClick={this.plusOneForEveyone}>
              <FormattedMessage
                id="lesson.inprogress.plusone"
                defaultMessage="+1 for everyone"
              />
            </Button>
          </Col>
        </Row>
        <Row style={{ paddingTop: '5px' }}>
          <Col xs={12}>
            <LessonTimer />
          </Col>
        </Row>
        <Row style={{ paddingTop: '5px' }}>
          <Col xs={12}>
            <GroupWork
              students={students.map(student => student.id)}
            />
          </Col>
        </Row>
        <Row style={{ paddingTop: '5px' }}>
          <Col xs={12}>
            {FinishConfirmWindow}
          </Col>
        </Row>
      </div>
    );

    const finishRow = (
      <Row style={{ marginTop: '20px' }}>
        <Col xs={2} xsOffset={10}>
          {FinishConfirmWindow}
        </Col>
      </Row>
    );

    const n = groups.length;
    const rowWithGROOUPZ = (n > 0) && (
      <div style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        padding: '30px',
      }}
      >
        {groups.map((group, elem) => (
          <div
            key={`group-with-${group[0]}`}
            style={{
              width: `${100 / n}%`,
              backgroundColor: groupColors[elem],
              padding: '10px',
            }}
            className="text-center"
          >

            <Button onClick={() => this.changeGroupScore(group, -1)}>
              <Glyphicon glyph="minus" />
            </Button>
            {' '}
            <Button onClick={() => this.changeGroupScore(group, +1)}>
              <Glyphicon glyph="plus" />
            </Button>

          </div>
        ))}

      </div>
    );

    const mobileGroupButtons = (n > 0) && (
      groups.map((group, elem) => (
        <Row
          key={`group-with-${group[0]}`}
          className="text-center"
          style={{
            backgroundColor: groupColors[elem],
            margin: '5px 10px 10px 0px',
            padding: '10px',
          }}
        >
          <Col xs={12}>
            <Button onClick={() => this.changeGroupScore(group, -1)}>
              <Glyphicon glyph="minus" />
            </Button>
            {' '}
            <Button onClick={() => this.changeGroupScore(group, +1)}>
              <Glyphicon glyph="plus" />
            </Button>
          </Col>
        </Row>
      ))
    );

    const mobileView = (
      <div>
        {mobileGroupButtons}
        {students
          .slice()
          .sort((a, b) => {
            const groupA = groups.findIndex(group => group.includes(a.id));
            const groupB = groups.findIndex(group => group.includes(b.id));
            return groupA - groupB;
          })
          .map(student => (
            <StudentRowMobile
              key={student.id}
              score={student.score}
              name={student.name}
              changeStudentScore={change => changeStudentScore(student.id, change)}
              groupColor={getGroupColor(student.id, groups)}
            />
          ))}
        {buttonBottomRowMobile}
      </div>
    );

    const fullDesktopView = (
      <div>
        {topRow}
        {rowWithGROOUPZ}
        {students
          .slice()
          .sort((a, b) => {
            const groupA = groups.findIndex(group => group.includes(a.id));
            const groupB = groups.findIndex(group => group.includes(b.id));
            return groupA - groupB;
          })
          .map((student, index) => (
            <StudentRowDesktop
              key={student.id}
              score={student.score}
              name={student.name}
              changeStudentScore={change => changeStudentScore(student.id, change)}
              theme={selectedTheme}
              backgroundColor={decideOnColor(index, false)}
              groupColor={getGroupColor(student.id, groups)}
            />
          ))}
        {finishRow}
      </div>
    );

    const condensedDesktopView = (
      <div>
        {topRow}
        {rowWithGROOUPZ}
        <Row className="text-center">
          {students
            .slice()
            .sort((a, b) => {
              const groupA = groups.findIndex(group => group.includes(a.id));
              const groupB = groups.findIndex(group => group.includes(b.id));
              return groupA - groupB;
            })
            .map((student, index) => (
              <StudentRowDesktopCondensed
                key={student.id}
                score={student.score}
                name={student.name}
                changeStudentScore={change => changeStudentScore(student.id, change)}
                theme={selectedTheme}
                backgroundColor={decideOnColor(index, true)}
                groupColor={getGroupColor(student.id, groups)}
              />
            ))}
          {finishRow}
        </Row>
      </div>
    );

    if (windowWidth <= 480) return mobileView;
    if (condensedLayout) return condensedDesktopView;
    return fullDesktopView;
  }
}


LessonInProgressView.propTypes = {
  changeStudentScore: PropTypes.func.isRequired,
  finishLesson: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
  ).isRequired,
  windowWidth: PropTypes.number.isRequired,
  selectedTheme: PropTypes.shape(themeShape).isRequired,
  batchChangeStudentScore: PropTypes.func.isRequired,
  students: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    score: PropTypes.number.isRequired,
    absent: PropTypes.bool.isRequired,
  })).isRequired,
};

const mapStateToProps = state => ({
  groups: state.groups.groups,
  selectedTheme: state.themes.selectedTheme,
});

const mapDispatchToProps = dispatch => ({
  finishGroupWork: () => dispatch({ type: 'FINISH_GROUP_WORK' }),
});


export default connect(mapStateToProps, mapDispatchToProps)(windowSize(LessonInProgressView));
