import React from 'react';
import PropTypes from 'prop-types';
import {
  ListGroup, ListGroupItem,
  ButtonGroup, Button, Glyphicon,
  Alert, FormGroup, FormControl, ControlLabel,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Confirm } from 'react-confirm-bootstrap';

import EditItemNameForm from './EditItemNameForm';
import NewItemForm from './NewItemForm';


class StudentsList extends React.Component {
  state = {
    studentUnderNameEditId: null,
    error: null,
  };

  handleFileSelect = (event) => {
    const { onAddStudents } = this.props;
    const { files } = event.target;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const file = evt.target.result;
      const allLines = file.split(/\r\n|\n/);
      if (allLines.length > 100) {
        this.setState({
          error: (
            <FormattedMessage
              id="setting.studentslist.morethan100"
              defaultMessage="We do not support groups with 100+ students, sorry."
            />
          ),
        });
      } else {
        onAddStudents(allLines);
        this.setState({ error: null });
      }
    };
    reader.onerror = evt => this.setState({ error: evt.target.error.name });
    reader.readAsText(files[0]);
  };

  render() {
    const {
      group, students, onUpdateStudentName, onRemoveStudent, onAddStudents, onClose,
    } = this.props;
    const {
      error, studentUnderNameEditId,
    } = this.state;

    if (!group) {
      return null;
    }
    return (
      <div>
        <h4>
          <FormattedMessage
            id="setting.studentslist.header"
            defaultMessage="Students in the {name} group:"
            values={{ name: group.name }}
          />
        </h4>

        <ListGroup>
          {students.map(student => (student.id === studentUnderNameEditId
            ? (
              <ListGroupItem key={student.id}>
                <EditItemNameForm
                  onSave={(name) => {
                    onUpdateStudentName(student.id, name);
                    this.setState({ studentUnderNameEditId: null });
                  }}
                  initValue={student.name}
                />
              </ListGroupItem>
            )
            : (
              <ListGroupItem key={student.id} className="clearfix">

                {student.name}

                <ButtonGroup className="pull-right">
                  <Button bsSize="sm" onClick={() => this.setState({ studentUnderNameEditId: student.id })}>
                    <Glyphicon glyph="pencil" />
                  </Button>
                  <Confirm
                    body={(
                      <FormattedMessage
                        id="setting.studentslist.delete.body"
                        defaultMessage="Are you sure you want to delete student {name}?"
                        values={{ name: student.name }}
                      />
)}
                    confirmText={(
                      <FormattedMessage
                        id="setting.studentslist.delete.confirmText"
                        defaultMessage="Confirm Delete"
                      />
)}
                    title={(
                      <FormattedMessage
                        id="setting.studentslist.delete.title"
                        defaultMessage="Deleting student"
                      />
)}
                    cancelText={(
                      <FormattedMessage
                        id="confirm.cancel"
                        defaultMessage="Cancel"
                      />
)}
                    onConfirm={() => onRemoveStudent(student.id)}
                  >
                    <Button bsSize="sm" bsStyle="danger">
                      <Glyphicon glyph="trash" />
                    </Button>
                  </Confirm>
                </ButtonGroup>
              </ListGroupItem>
            )))}
        </ListGroup>

        <NewItemForm
          onAdd={x => onAddStudents([x])}
            // TODO: Placeholder is missing
          label={(
            <FormattedMessage
              id="setting.studentslist.new.label"
              defaultMessage="Add new student in this group"
            />
)}
        />

        <FormGroup>
          <ControlLabel>
            <FormattedMessage
              id="setting.studentslist.uploadbutton"
              defaultMessage="Or upload students list (max 100 students)"
            />
          </ControlLabel>
          <FormControl
            type="file"
            accept=".txt"
            onChange={event => this.handleFileSelect(event)}
          />
        </FormGroup>

        {error && (
          <Alert bsStyle="warning" style={{ paddingTop: '20px' }}>
            {error}
          </Alert>
        )}

        <Button bsStyle="primary" onClick={() => onClose()}>
          <FormattedMessage
            id="setting.studentslist.close"
            defaultMessage="Close"
          />
        </Button>

      </div>
    );
  }
}

StudentsList.defaultProps = {
  group: null,
};

StudentsList.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  students: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  onClose: PropTypes.func.isRequired,
  onAddStudents: PropTypes.func.isRequired,
  onUpdateStudentName: PropTypes.func.isRequired,
  onRemoveStudent: PropTypes.func.isRequired,
};


export default StudentsList;
