export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SETUP_GROUPS = 'SETUP_GROUPS';
export const FINISH_GROUP_WORK = 'FINISH_GROUP_WORK';


const defaultState = { modalIsOpen: false, groups: [] };

export default (state = defaultState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return { ...state, modalIsOpen: true };

    case HIDE_MODAL:
      return { ...state, modalIsOpen: false };

    case SETUP_GROUPS:
      return { ...state, groups: action.groups };

    case FINISH_GROUP_WORK:
      return { modalIsOpen: false, groups: [] };

    default:
      return state;
  }
};
