import React from 'react';
import PropTypes from 'prop-types';
import {
  Navbar, Nav, NavItem, Button,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { firebaseAuth } from '../config/firebaseConfig';

import LanguageSelector from './LanguageSelector';

import logo from '../images/zebraclassroom_logo1.svg';

const Logo = () => (
  <Navbar.Header>
    <Link to="/">
      <img
        src={logo}
        style={{ height: '45px', paddingTop: '5px', marginRight: '20px' }}
        alt="Zebra Classroom"
      />
    </Link>
    <Navbar.Toggle />
  </Navbar.Header>
);

export const Navigation = ({ isLoggedIn }) => (
  <Navbar collapseOnSelect>
    <Logo />
    <Navbar.Collapse>
      <Nav>
        <LinkContainer to="/lesson">
          <NavItem>
            <FormattedMessage
              id="navigation.lesson"
              defaultMessage="Lesson"
            />
          </NavItem>
        </LinkContainer>
        {isLoggedIn && (
          <LinkContainer to="/settings">
            <NavItem>
              <FormattedMessage
                id="navigation.settings"
                defaultMessage="Settings"
              />
            </NavItem>
          </LinkContainer>
        )}
        {isLoggedIn && (
          <LinkContainer to="/history">
            <NavItem>
              <FormattedMessage
                id="navigation.history"
                defaultMessage="History"
              />
            </NavItem>
          </LinkContainer>
        )}
        <LinkContainer to="/faq">
          <NavItem>
            <FormattedMessage
              id="navigation.faq"
              defaultMessage="FAQ"
            />
          </NavItem>
        </LinkContainer>
        {/* <LinkContainer to="/plus">
          <NavItem>
            <FormattedMessage
              id="navigation.plus"
              defaultMessage="Plus"
            />
          </NavItem>
        </LinkContainer> */}
      </Nav>
      <Navbar.Form pullRight>
        <LanguageSelector />
      </Navbar.Form>
      {
        isLoggedIn
          ? (
            <Navbar.Form pullRight>
              <Button bsStyle="primary" onClick={() => firebaseAuth.signOut()}>
                <FormattedMessage
                  id="navigation.logout"
                  defaultMessage="Logout"
                />
              </Button>
            </Navbar.Form>
          )
          : (
            <Navbar.Form pullRight>
              <LinkContainer to="/login">
                {/* TODO: Maybe do not make active when on the page. Hint: MenuItem */}
                <Button bsStyle="primary" active={false}>
                  <FormattedMessage
                    id="navigation.login"
                    defaultMessage="Login"
                  />
                </Button>
              </LinkContainer>
            </Navbar.Form>
          )
      }
    </Navbar.Collapse>
  </Navbar>
);


Navigation.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};


export const NavigationStatic = () => (
  <Navbar collapseOnSelect>
    <Logo />
    <Navbar.Collapse>
      <Nav>
        <LinkContainer to="/lesson">
          <NavItem>
            <FormattedMessage
              id="navigation.lesson"
              defaultMessage="Lesson"
            />
          </NavItem>
        </LinkContainer>
        <LinkContainer to="/settings">
          <NavItem>
            <FormattedMessage
              id="navigation.settings"
              defaultMessage="Settings"
            />
          </NavItem>
        </LinkContainer>
        <LinkContainer to="/history">
          <NavItem>
            <FormattedMessage
              id="navigation.history"
              defaultMessage="History"
            />
          </NavItem>
        </LinkContainer>
        <LinkContainer to="/faq">
          <NavItem>
            <FormattedMessage
              id="navigation.faq"
              defaultMessage="FAQ"
            />
          </NavItem>
        </LinkContainer>
        {/* <LinkContainer to="/plus">
          <NavItem>
            <FormattedMessage
              id="navigation.plus"
              defaultMessage="Plus"
            />
          </NavItem>
        </LinkContainer> */}
      </Nav>
      <Navbar.Form pullRight>
        <LanguageSelector />
      </Navbar.Form>
      <Nav pullRight>
        <NavItem disabled>
          <FontAwesome spin name="spinner" size="lg" style={{ color: '#333333' }} />
        </NavItem>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);
