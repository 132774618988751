import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Grid, Row, Col, Table, Button, Glyphicon, Pager,
} from 'react-bootstrap';
import { Confirm } from 'react-confirm-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';


const getDateFromTimestamp = (unixTimeStamp) => {
  const date = new Date(unixTimeStamp);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};


const getTimeFromTimestamp = (unixTimeStamp) => {
  const date = new Date(unixTimeStamp);
  return `${date.getHours()}:${(`0${date.getMinutes()}`).slice(-2)}`;
};


const ScoresTable = ({ lesson, deleteLesson, deletingInProgress }) => (
  deletingInProgress
    ? (
      <Row>
        <Col xs={12}>
          <h3>
            <FormattedMessage
              id="history.scorestable.deleting"
              defaultMessage="Deleting..."
            />
          </h3>
        </Col>
      </Row>
    )
    : (
      <div>
        <Row>
          <Col xs={12}>
            <h3>
              <FormattedMessage
                id="history.scorestable.header"
                defaultMessage="Lesson with {groupName}"
                values={{ groupName: lesson.groupName }}
              />
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Table bordered condensed responsive>
              <thead>
                <tr>
                  <th style={{ width: '70%' }}>
                    <FormattedMessage
                      id="history.scorestable.name"
                      defaultMessage="Name"
                    />
                  </th>
                  <th style={{ width: '15%' }}>
                    <FormattedMessage
                      id="history.scorestable.score"
                      defaultMessage="Score"
                    />
                  </th>
                  <th style={{ width: '15%' }}>
                    <FormattedMessage
                      id="history.scorestable.grade"
                      defaultMessage="Grade"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {lesson.scores.map(student => (
                  <tr key={student.id}>
                    <td>{student.name}</td>
                    <td>{student.score}</td>
                    <td>{student.mark}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Confirm
              body={(
                <FormattedMessage
                  id="history.delete.body"
                  defaultMessage="Are you sure you want to delete the lesson? This action cannot be undone."
                />)}
              confirmText={(
                <FormattedMessage
                  id="history.delete.confirmText"
                  defaultMessage="Confirm"
                />)}
              title={(
                <FormattedMessage
                  id="history.delete.title"
                  defaultMessage="Delete the lesson"
                />)}
              cancelText={(
                <FormattedMessage
                  id="confirm.cancel"
                  defaultMessage="Cancel"
                />)}
              onConfirm={deleteLesson}
            >
              <Button bsStyle="danger">
                <Glyphicon glyph="trash" style={{ marginRight: '5px' }} />
                <FormattedMessage
                  id="history.delete.button"
                  defaultMessage="Delete this lesson"
                />
              </Button>
            </Confirm>
          </Col>
        </Row>
      </div>
    )
);

ScoresTable.defaultProps = {
  lesson: null,
};

ScoresTable.propTypes = {
  deleteLesson: PropTypes.func.isRequired,
  deletingInProgress: PropTypes.bool.isRequired,
  lesson: PropTypes.shape({
    groupName: PropTypes.string.isRequired,
    scores: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
      mark: PropTypes.string.isRequired,
    })).isRequired,
  }),
};


const HistoryNavigationPlus = ({
  clickPrevious, disabledPrevious, clickNext, disabledNext,
}) => (
    <Pager>
      <Pager.Item
        previous
        onClick={clickPrevious}
        disabled={disabledPrevious}
      >
        <FormattedMessage
          id="history.nav.previous10"
          defaultMessage="Previous 10 lessons"
        />
      </Pager.Item>
      {' '}
      <Pager.Item
        next
        onClick={clickNext}
        disabled={disabledNext}
      >
        <FormattedMessage
          id="history.nav.next10"
          defaultMessage="Next 10 lessons"
        />
      </Pager.Item>
    </Pager>
  );

HistoryNavigationPlus.propTypes = {
  clickPrevious: PropTypes.func.isRequired,
  clickNext: PropTypes.func.isRequired,
  disabledPrevious: PropTypes.bool.isRequired,
  disabledNext: PropTypes.bool.isRequired,
};


const HistoryNavigationBasic = () => (
  <Pager>
    <Pager.Item
      previous
      disabled
    >
      <FormattedMessage
        id="history.nav.previous10"
        defaultMessage="Previous 10 lessons"
      />
    </Pager.Item>
    <span>
      <FormattedMessage
        id="history.nav.zbc"
        defaultMessage="History navigation is only available in {link}"
        values={{
          link: <Link to="/plus">Zebra Classroom Plus.</Link>,
        }}
      />
    </span>
    <Pager.Item
      next
      disabled
    >
      <FormattedMessage
        id="history.nav.next10"
        defaultMessage="Next 10 lessons"
      />
    </Pager.Item>
  </Pager>
);

class History extends React.Component {
  state = {
    loading: true,
    lessons: [],
    selectedLesson: null,
    deleteLesson: false,
    page: 1,
  };

  componentDidMount() {
    const { userRef } = this.props;
    userRef
      .collection('lessons')
      .orderBy('finishedTimestamp', 'desc')
      .limit(10)
      .get()
      .then((res) => {
        const lessons = [];
        res.forEach(item => lessons.push({
          ...item.data(),
          id: item.id,
        }));
        this.setState({
          loading: false,
          lessons,
        });
      });
  }

  deleteLesson = (lessonToDeleteId) => {
    const { userRef } = this.props;
    const { lessons } = this.state;
    this.setState({ deleteLesson: true });
    const lessonToDeleteRef = userRef.collection('lessons').doc(lessonToDeleteId);
    const nextLesson = userRef.collection('lessons')
      .orderBy('finishedTimestamp', 'desc')
      .startAfter(lessons[lessons.length - 1].finishedTimestamp)
      .limit(1);

    lessonToDeleteRef.delete().then(() => {
      nextLesson.get().then((next) => {
        const shrinkedList = lessons
          .filter(lesson => lesson.id !== lessonToDeleteId);

        if (next.size === 1) {
          const toadd = { ...next.docs[0].data(), id: next.docs[0].id };
          this.setState({
            lessons: [...shrinkedList, toadd],
            selectedLesson: null,
            deleteLesson: false,
          });
        } else {
          this.setState({
            lessons: shrinkedList,
            selectedLesson: null,
            deleteLesson: false,
          });
        }
      });
    });
  };

  clickNext = () => {
    const { userRef } = this.props;
    const { lessons, page } = this.state;
    userRef.collection('lessons')
      .orderBy('finishedTimestamp', 'desc')
      .startAfter(lessons[lessons.length - 1].finishedTimestamp)
      .limit(10)
      .get()
      .then((res) => {
        const newLessons = [];
        res.forEach(item => newLessons.push({
          ...item.data(),
          id: item.id,
        }));
        this.setState({
          loading: false,
          lessons: newLessons,
          page: page + 1,
          selectedLesson: null,
          deleteLesson: false,
        });
      });
  }

  clickPrevious = () => {
    const { userRef } = this.props;
    const { lessons, page } = this.state;
    userRef.collection('lessons')
      .orderBy('finishedTimestamp')
      .startAfter(lessons[0].finishedTimestamp)
      .limit(10)
      .get()
      .then((res) => {
        const newLessons = [];
        res.forEach(item => newLessons.push({
          ...item.data(),
          id: item.id,
        }));
        this.setState({
          loading: false,
          lessons: newLessons.reverse(),
          page: page - 1,
          selectedLesson: null,
          deleteLesson: false,
        });
      });
  }

  handleLessonRowClick = (lesson) => {
    window.mixpanel.track('Lesson Selected From History');
    this.setState({ selectedLesson: lesson });
  }

  render() {
    const { plus } = this.props;
    const {
      loading, lessons, page, selectedLesson, deleteLesson,
    } = this.state;

    const helmet = (
      <Helmet>
        <title>Zebra Classroom - History</title>
      </Helmet>
    );

    const HistoryNavigationToShow = plus ? (
      <HistoryNavigationPlus
        clickNext={this.clickNext}
        clickPrevious={this.clickPrevious}
        disabledNext={lessons.length < 10}
        disabledPrevious={page === 1}
      />
    ) : <HistoryNavigationBasic />;

    if (loading) {
      return (
        <div>
          {helmet}
          <Grid>
            <Row>
              <Col xs={12}>
                <h3>
                  <FormattedMessage
                    id="history.fetching"
                    defaultMessage="Hold on. Fetching your lessons..."
                  />
                </h3>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
    return (
      <div>
        {helmet}
        <Grid>
          <Row>
            <Col xs={12}>
              <h3>
                <FormattedMessage
                  id="history.header"
                  defaultMessage="Your history of lessons"
                />
              </h3>
              <p>
                <FormattedMessage
                  id="history.clickhere"
                  defaultMessage="Click at the row to view the scores"
                />
              </p>
              <Table bordered condensed hover responsive>
                <thead>
                  <tr>
                    <th style={{ width: '40%' }}>
                      <FormattedMessage
                        id="history.table.group"
                        defaultMessage="Group"
                      />
                    </th>
                    <th style={{ width: '15%' }}>
                      <FormattedMessage
                        id="history.table.startdate"
                        defaultMessage="Start date"
                      />
                    </th>
                    <th style={{ width: '15%' }}>
                      <FormattedMessage
                        id="history.table.starttime"
                        defaultMessage="Start time"
                      />
                    </th>
                    <th style={{ width: '15%' }}>
                      <FormattedMessage
                        id="history.table.finishdate"
                        defaultMessage="Finish date"
                      />
                    </th>
                    <th style={{ width: '15%' }}>
                      <FormattedMessage
                        id="history.table.finishtime"
                        defaultMessage="Finish time"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {lessons.map(lesson => (
                    <tr
                      key={lesson.id}
                      className={
                        selectedLesson && (selectedLesson.id === lesson.id)
                          ? 'info'
                          : null
                      }
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.handleLessonRowClick(lesson)}
                    >
                      <td>
                        {lesson.groupName}
                        {' '}
                      </td>
                      <td>{getDateFromTimestamp(lesson.startedTimestamp)}</td>
                      <td>{getTimeFromTimestamp(lesson.startedTimestamp)}</td>
                      <td>{getDateFromTimestamp(lesson.finishedTimestamp)}</td>
                      <td>{getTimeFromTimestamp(lesson.finishedTimestamp)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {HistoryNavigationToShow}

            </Col>
          </Row>
          {selectedLesson
            && (
              <ScoresTable
                deleteLesson={() => this.deleteLesson(selectedLesson.id)}
                lesson={selectedLesson}
                deletingInProgress={deleteLesson}
              />
            )}
        </Grid>
      </div>
    );
  }
}


History.propTypes = {
  userRef: PropTypes.shape({}).isRequired,
  plus: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  userRef: state.user.ref,
  // plus: state.user.plus,
  plus: true, // We will set plus for everyone for now
});

export default connect(mapStateToProps, null)(History);
