import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, FormGroup, FormControl, Button, Well,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';


class LessonStartNewView extends React.Component {
  state = { selected: null };

  componentDidMount() {
    const { groups } = this.props;
    if (groups.length) {
      this.setState({ selected: groups && groups[0].id });
    }
  }

  onChange = event => this.setState({ selected: event.target.value });

  render() {
    const { groups, proceedToMarkAbsent } = this.props;
    const { selected } = this.state;

    if (groups.length) {
      return (
        <Well>
          <Form>
            <FormGroup controlId="formControlsSelect">
              <h4>
                <FormattedMessage
                  id="lesson.start.header"
                  defaultMessage="Start an awesome lesson with"
                />
              </h4>

              <FormControl componentClass="select" placeholder="select" onChange={this.onChange}>
                {groups.map(group => <option key={group.id} value={group.id}>{group.name}</option>)}
              </FormControl>
            </FormGroup>
            <Button bsStyle="primary" onClick={() => proceedToMarkAbsent(selected)}>
              <FormattedMessage
                id="lesson.start.continue"
                defaultMessage="Continue"
              />
            </Button>
          </Form>
        </Well>
      );
    }
    return (
      <Well>
        <h3>
          <FormattedMessage
            id="lesson.start.nogroups.header"
            defaultMessage="You don't have any groups."
          />
        </h3>
        <p>
          <FormattedMessage
            id="lesson.start.nogroups.explanation"
            defaultMessage="Add them on the Settings page to start a lesson."
          />
        </p>
      </Well>
    );
  }
}

LessonStartNewView.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  proceedToMarkAbsent: PropTypes.func.isRequired,
};

export default LessonStartNewView;
