import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Image, Button, Glyphicon,
} from 'react-bootstrap';
import { clipScore } from '../Lesson';
import { imageStyle } from '../LessonViews/InProgress';
import { themeShape } from '../../reducers/themes';

const DEMO_STUDENT_NAME = 'Salvador Dalí';

class StudentRowDesktopDemo extends React.Component {
  state = { score: 4 };

  changeStudentScore = (delta) => {
    this.setState(prevState => ({ score: clipScore(prevState.score + delta) }));
  }

  render() {
    const { score } = this.state;
    const { theme } = this.props;
    return (
      <Row
        className="text-center"
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '70px',
        }}
      >
        <Col className="text-center" xs={1}>
          {score < 0 && (
            <Image
              style={imageStyle}
              rounded
              src={theme.minus1}
            />
          )}
        </Col>
        <Col xs={1}>
          <Button bsStyle="danger" onClick={() => this.changeStudentScore(-1)}>
            <Glyphicon glyph="minus" />
          </Button>
        </Col>
        <Col xs={4} style={{ display: 'flex' }}>
          <div style={{ width: '90%', fontSize: '22px' }}>
            {score === 5 ? (
              <b>
                {DEMO_STUDENT_NAME}
                {' '}
                <Glyphicon glyph="star" />
              </b>
            ) : DEMO_STUDENT_NAME}
          </div>
        </Col>
        <Col xs={1}>
          <Button bsStyle="success" onClick={() => this.changeStudentScore(+1)}>
            <Glyphicon glyph="plus" />
          </Button>
        </Col>
        <Col xs={1}>
          {score >= 1 && (
            <Image
              style={imageStyle}
              rounded
              src={theme.plus1}
            />
          )}
        </Col>
        <Col xs={1}>
          {score >= 2 && (
            <Image
              style={imageStyle}
              rounded
              src={theme.plus2}
            />
          )}
        </Col>
        <Col xs={1}>
          {score >= 3 && (
            <Image
              style={imageStyle}
              rounded
              src={theme.plus3}
            />
          )}
        </Col>
        <Col xs={1}>
          {score >= 4 && (
            <Image
              style={imageStyle}
              rounded
              src={theme.plus4}
            />
          )}
        </Col>
        <Col xs={1}>
          {score >= 5 && (
            <Image
              style={imageStyle}
              rounded
              src={theme.plus5}
            />
          )}
        </Col>
      </Row>
    );
  }
}

StudentRowDesktopDemo.propTypes = {
  theme: PropTypes.shape(themeShape).isRequired,
};

export default StudentRowDesktopDemo;
