import { updateIntl } from 'react-intl-redux';
import messagesEn from './en.json';
import messagesRu from './ru.json';

const en = {
  locale: 'en',
  messages: messagesEn,
};

const ru = {
  locale: 'ru',
  messages: messagesRu,
};


export const switchLocale = (locale) => {
  window.mixpanel.track('Switch locale', { 'Locale': locale });
  localStorage.setItem('locale', locale);
  return locale === 'ru' ? updateIntl(ru) : updateIntl(en);
};


const getInitialState = () => {
  // Local storage is a priority
  // Otherwise fallback to language, userLanguage and English
  const userLang = navigator.language || navigator.userLanguage || 'en';
  const userLocale = localStorage.getItem('locale') || userLang.split('-')[0];

  return userLocale === 'ru' ? ru : en;
};

export default getInitialState();
