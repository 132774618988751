import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';


const ContactPage = () => (
  <div>
    <Helmet>
      <title>Zebra Classroom - Contacts</title>
    </Helmet>

    <Grid>
      <Row style={{ marginBottom: '120px' }}>
        <Col xs={12} mdOffset={2} md={8} className="text-justify">
          <h1 className="text-center" style={{ paddingTop: '16px', paddingBottom: '48px' }}>
            <FormattedMessage
              id="contact.header"
              defaultMessage="Contact us"
            />
          </h1>

          <p>
            <FormattedMessage
              id="contact.email"
              defaultMessage="You can reach us at"
            />
            &nbsp;
            <b>contact@zebraclassroom.com</b>
          </p>

          <p>
            <FormattedMessage
              id="contact.fb"
              defaultMessage="Or drop a message to our facebook:"
            />
            &nbsp;
            <a href="http://fb.me/zebraclassroom">
              fb.me/zebraclassroom
            </a>
          </p>

        </Col>
      </Row>
    </Grid>
  </div>
);

export default ContactPage;
