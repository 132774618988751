import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Button, ButtonGroup, Glyphicon,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { selectRandomElement, selectRandomElements } from './RandomStudentSelector';


class RandomStudentSelectorLocal extends React.Component {
  constructor(props) {
    super(props);
    const { students } = this.props;

    this.state = {
      show: false,
      rolling: false,
      selected: '',
      availablePool: students,
    };
  }

  showTime = (fakeSelected, realSelected) => {
    const { availablePool } = this.state;
    const { students } = this.props;

    const delay = 200;
    fakeSelected.forEach((fake, index) => setTimeout(() => {
      this.setState({ show: true, rolling: true, selected: fake });
    }, delay * index));

    setTimeout(() => {
      const newPool = availablePool.filter(elem => elem.id !== realSelected.id);
      this.setState({
        show: true,
        selected: realSelected,
        rolling: false,
        availablePool: (newPool.length === 0) ? students : newPool,
      });
    }, delay * fakeSelected.length);
  }

  handleSelect = () => {
    const { availablePool } = this.state;
    const { students } = this.props;

    const real = selectRandomElement(availablePool);
    const realSelected = {
      name: real.name,
      id: real.id,
    };

    const studentsToDraw = Math.min(
      Math.floor((Math.random() * 4) + 3), // form 3 to 6
      students.length - 1, // but no more than length of the students
    );
    const fakeSelected = selectRandomElements(
      students.filter(student => student.id !== realSelected.id),
      studentsToDraw,
    ).map(student => ({ name: student.name }));

    this.showTime(fakeSelected, realSelected);
  };

  close = () => {
    this.setState({ show: false, rolling: false });
  }

  render() {
    const { selected, rolling, show } = this.state;
    const { changeStudentScore } = this.props;

    const selectedHeader = (
      <h1>
        <Button
          bsStyle="danger"
          bsSize="sm"
          style={{ marginRight: '12px' }}
          onClick={() => {
            changeStudentScore(selected.id, -1);
            this.close();
          }}
        >
          <Glyphicon glyph="minus" />
        </Button>

        {selected.name}

        <Button
          bsStyle="success"
          bsSize="sm"
          style={{ marginLeft: '12px' }}
          onClick={() => {
            changeStudentScore(selected.id, +1);
            this.close();
          }}
        >
          <Glyphicon glyph="plus" />
        </Button>
      </h1>
    );

    return (
      <div>
        <Button block onClick={this.handleSelect}>

          <Glyphicon glyph="random" style={{ marginRight: '5px' }} />
          <FormattedMessage
            id="lesson.inprogress.random"
            defaultMessage="Random student"
          />
        </Button>

        <Modal
          show={show}
          onHide={this.close}
          animation
        >
          <Modal.Header className="text-center" style={{ height: '100px' }}>
            {
              rolling
                ? <h3>{selected.name}</h3>
                : selectedHeader
            }
          </Modal.Header>
          <Modal.Body className="text-center">
            <ButtonGroup>
              <Button onClick={this.handleSelect}>
                <FormattedMessage
                  id="custom.lesson.inprogress.random.another"
                  defaultMessage="Another one"
                />
              </Button>
              <Button onClick={this.close} bsStyle="info">
                <FormattedMessage
                  id="custom.lesson.inprogress.random.close"
                  defaultMessage="Close"
                />
              </Button>
            </ButtonGroup>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

RandomStudentSelectorLocal.propTypes = {
  students: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
  changeStudentScore: PropTypes.func.isRequired,
};

export default RandomStudentSelectorLocal;
