import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { firestore } from '../config/firebaseConfig';

import LessonStartNewView from './LessonViews/StartNew';
import LessonMarkAbsentView from './LessonViews/MarkAbsent';
import LessonInProgressView from './LessonViews/InProgress';
import LessonFinishedView from './LessonViews/Finished';
import pixel from '../config/fb';

import { FINISH_GROUP_WORK } from '../reducers/groups';


export const clipScore = score => Math.min(5, Math.max(-1, score));


class Lesson extends React.Component {
  state = {
    groups: [],
    students: [],
    stage: 'Loading',
  };

  componentDidMount() {
    const { userRef } = this.props;
    const { stage } = this.state;

    this.unsubscribe = userRef.collection('activeLesson').orderBy('name')
      .onSnapshot((querySnapshot) => {
        if (querySnapshot && querySnapshot.size) {
          const students = [];
          querySnapshot.forEach(doc => students.push({ ...doc.data(), id: doc.id }));
          this.setState({
            students,
            stage: 'InProgress',
          });
        } else if (stage === 'Loading') {
          userRef.collection('groups').orderBy('name').get()
            .then((querySnapshotGroups) => {
              const groups = [];
              querySnapshotGroups.forEach(doc => groups.push({ ...doc.data(), id: doc.id }));
              this.setState({
                groups,
                stage: 'StartNew',
              });
            });
        } else {
          this.setState({ stage: 'Finished' });
        }
      });
  }

  componentWillUnmount() {
    if (this.unsubscribe && (this.unsubscribe instanceof Function)) this.unsubscribe();
  }

  proceedToMarkAbsent = (groupID) => {
    window.mixpanel.track('Proceed To Mark Absent');
    const { userRef } = this.props;
    const { groups } = this.state;

    userRef.collection('groups').doc(groupID)
      .collection('students').orderBy('name')
      .get()
      .then((querySnapshot) => {
        const students = [];
        querySnapshot.forEach(doc => students.push({
          ...doc.data(),
          id: doc.id,
          absent: false,
          score: 0,
        }));
        this.setState({
          students,
          stage: 'MarkAbsent',
          groupInMarkAbsentID: groupID,
          groupInMarkAbsentName: groups.find(group => group.id === groupID).name,
        });
      });
  };

  switchStudentAbsent = (studentID) => {
    const { students } = this.state;

    const index = students.findIndex(student => student.id === studentID);
    const studentToChange = students[index];
    this.setState({
      students: [
        ...students.slice(0, index),
        { ...studentToChange, absent: !studentToChange.absent },
        ...students.slice(index + 1),
      ],
    });
  };

  goBackToStartPage = () => {
    this.setState({ stage: 'StartNew' });
  };

  proceedToInProgress = () => {
    window.mixpanel.track('Proceed To In Progress');
    const { userRef } = this.props;
    const {
      groupInMarkAbsentID, groupInMarkAbsentName, students,
    } = this.state;

    pixel.track('ViewContent', { content_name: 'proceedToInProgress' });
    const now = Date.now();
    const lessonMeta = {
      groupID: groupInMarkAbsentID,
      groupName: groupInMarkAbsentName,
      startedTimestamp: Math.floor(now),
    };

    const batch = firestore.batch();
    batch.update(userRef, { activeLesonMeta: lessonMeta });

    students.forEach((student) => {
      const newScore = userRef.collection('activeLesson').doc(student.id);
      batch.set(newScore, student);
    });
    batch.commit().then(() => this.setState({ stage: 'InProgress' }));
  };

  finishLesson = () => {
    window.mixpanel.track('Finish Lesson');
    const { userRef, finishGroupWork } = this.props;
    const { students } = this.state;

    pixel.track('ViewContent', { content_name: 'finishLesson' });
    const activeLessonRef = userRef.collection('activeLesson');
    const getUser = userRef.get();
    const getActiveLesson = activeLessonRef.get();

    Promise.all([getUser, getActiveLesson]).then((values) => {
      const user = values[0].data();
      const now = Date.now();

      const storeLesson = userRef.collection('lessons').add({
        ...user.activeLesonMeta,
        finishedTimestamp: Math.floor(now),
        scores: students.map(student => ({
          ...student,
          mark: user.matchingTable[student.score + 1],
        })),
      });

      const batch = firestore.batch();
      batch.update(userRef, { activeLesonMeta: null });
      values[1].forEach(student => batch.delete(activeLessonRef.doc(student.id)));
      const removeCurrentLesson = batch.commit();

      Promise.all([storeLesson, removeCurrentLesson]).then(() => {
        this.setState({ stage: 'Finished' });
      });
    });

    finishGroupWork(); // TODO: finish on the server
  };

  changeStudentScore = (studentId, change) => {
    const { userRef } = this.props;
    const { students } = this.state;

    const currentScore = students.find(student => student.id === studentId).score;
    const newScore = clipScore(currentScore + change);

    if (currentScore !== newScore) {
      userRef.collection('activeLesson').doc(studentId)
        .update({ score: newScore });
    }
  };

  batchChangeStudentScore = (studentIds, change) => {
    const { userRef } = this.props;
    const { students } = this.state;

    const batch = firestore.batch();

    studentIds.forEach((studentId) => {
      const currentScore = students.find(student => student.id === studentId).score;
      const newScore = clipScore(currentScore + change);

      if (currentScore !== newScore) {
        batch.update(
          userRef.collection('activeLesson').doc(studentId),
          { score: newScore },
        );
      }
    });

    batch.commit();
  };

  selectStage = (stage) => {
    const { groups, students } = this.state;

    if (stage === 'Loading') {
      return (
        <h3>
          <FormattedMessage
            id="lesson.loading"
            defaultMessage="Loading..."
          />
        </h3>
      );
    } if (stage === 'StartNew') {
      return (
        <LessonStartNewView
          groups={groups}
          proceedToMarkAbsent={this.proceedToMarkAbsent}
        />
      );
    } if (stage === 'MarkAbsent') {
      return (
        <LessonMarkAbsentView
          students={students}
          switchStudentAbsent={this.switchStudentAbsent}
          goBack={this.goBackToStartPage}
          startLesson={this.proceedToInProgress}
        />
      );
    } if (stage === 'InProgress') {
      return (
        <LessonInProgressView
          students={students.filter(student => !student.absent)}
          changeStudentScore={this.changeStudentScore}
          batchChangeStudentScore={this.batchChangeStudentScore}
          finishLesson={this.finishLesson}
        />
      );
    } if (stage === 'Finished') {
      return (
        <LessonFinishedView
          students={students}
          goBack={this.goBackToStartPage}
        />
      );
    }
    return (
      <h3>Error :(</h3>
    );
  };

  render() {
    const { stage } = this.state;

    pixel.track('ViewContent', { content_name: 'LessonPage' });
    return (
      <div>
        <Helmet>
          <title>Zebra Classroom - Lesson</title>
        </Helmet>
        <Grid>
          <Row>
            <Col xs={12}>
              {this.selectStage(stage)}
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

Lesson.propTypes = {
  userRef: PropTypes.shape({}).isRequired,
  finishGroupWork: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
  userRef: state.user.ref,
});

const mapDispatchToProps = dispatch => ({
  finishGroupWork: () => dispatch({ type: FINISH_GROUP_WORK }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Lesson);
