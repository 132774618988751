import { combineReducers } from 'redux';
import { intlReducer } from 'react-intl-redux';
import groups from './groups';
import themes from './themes';
import user from './user';

export default combineReducers({
  groups,
  themes,
  user,
  intl: intlReducer,
});
