import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Modal, Button, ButtonGroup, Glyphicon,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Timer from 'timer.js';
import timerSound from '../../timerSound.mp3';


export const secondsToFomattedTime = (total) => {
  const minutes = Math.floor(total / 60);
  const seconds = total % 60;
  return `${(`0${minutes}`).slice(-2)}:${(`0${seconds}`).slice(-2)}`;
};


export const ButtonGroupCounting = ({ hitPause, hitStop }) => (
  <ButtonGroup>
    <Button
      bsStyle="warning"
      onClick={hitPause}
    >
      <Glyphicon glyph="pause" />
    </Button>
    <Button
      bsStyle="danger"
      onClick={hitStop}
    >
      <Glyphicon glyph="stop" />
    </Button>
  </ButtonGroup>
);

ButtonGroupCounting.propTypes = {
  hitPause: PropTypes.func.isRequired,
  hitStop: PropTypes.func.isRequired,
};


export const ButtonGroupNew = ({ setTime }) => (
  <ButtonGroup>
    <Button onClick={() => setTime(1 * 60)}>
      <FormattedMessage
        id="timer.options.option2"
        defaultMessage="1 minute"
      />
    </Button>
    <Button onClick={() => setTime(2 * 60)}>
      <FormattedMessage
        id="timer.options.option3"
        defaultMessage="2 minutes"
      />
    </Button>
    <Button onClick={() => setTime(3 * 60)}>
      <FormattedMessage
        id="timer.options.option3min"
        defaultMessage="3 minutes"
      />
    </Button>
    <Button onClick={() => setTime(5 * 60)}>
      <FormattedMessage
        id="timer.options.option4"
        defaultMessage="5 minutes"
      />
    </Button>
    <Button onClick={() => setTime(10 * 60)}>
      <FormattedMessage
        id="timer.options.option5"
        defaultMessage="10 minutes"
      />
    </Button>
  </ButtonGroup>
);

ButtonGroupNew.propTypes = {
  setTime: PropTypes.func.isRequired,
};


export const ButtonGroupPaused = ({ hitPlay, hitStop }) => (
  <ButtonGroup>
    <Button
      bsStyle="success"
      onClick={hitPlay}
    >
      <Glyphicon glyph="play" />
    </Button>
    <Button
      bsStyle="danger"
      onClick={hitStop}
    >
      <Glyphicon glyph="stop" />
    </Button>
  </ButtonGroup>
);

ButtonGroupPaused.propTypes = {
  hitPlay: PropTypes.func.isRequired,
  hitStop: PropTypes.func.isRequired,
};


class LessonTimer extends React.Component {
  constructor(props) {
    super(props);
    const { userRef } = this.props;

    this.timerConfig = {
      tick: 1,
      ontick: () => this.forceUpdate(),
      onstart: () => this.setState({ endMessage: null }),
      onpause: () => this.forceUpdate(),
      onend: () => {
        var sound = document.getElementById("timerSound");
        sound && sound.play();  // Maybe just select and play
        this.setState({
          endMessage: <FormattedMessage
            id="timer.timeisup"
            defaultMessage="Time's up!"
          />,
        });
        userRef.update({ timerData: null });
      },
    };

    this.state = {
      modalShow: false,
      endMessage: null,
      timer: new Timer(this.timerConfig),
      muted: true,
    };
  }

  componentDidMount() {
    const { userRef } = this.props;

    this.unsubscribe = userRef.onSnapshot((doc) => {
      const { timer } = this.state;
      const { timerData } = doc.data();

      if (timerData) {
        const ms = timerData.endingTime - new Date().getTime();
        this.setState({ modalShow: timerData.modalShow });
        if (ms > 0) {
          if (timerData.status === 'started') {
            timer.start(ms / 1000);
          }
          if (timerData.status === 'paused') {
            timer.start(timerData.left / 1000).pause();
          }
        } else {
          this.setState({ timer: new Timer(this.timerConfig) });
        }
      } else {
        this.setState({ timer: new Timer(this.timerConfig) });
      }
    });
  }

  componentWillUnmount() {
    const { timer } = this.state;
    timer.off('all');
    if (this.unsubscribe && (this.unsubscribe instanceof Function)) this.unsubscribe();
  }


  showModal = () => {
    const { userRef } = this.props;
    this.setState({ modalShow: true, endMessage: null });
    userRef.update({ 'timerData.modalShow': true });
  }

  hideModal = () => {
    const { userRef } = this.props;
    this.setState({ modalShow: false, endMessage: null });
    userRef.update({ 'timerData.modalShow': false });
  }

  setTime = (seconds) => {
    const { timer } = this.state;
    timer.start(seconds);
    this.hitPlay();
  }

  hitPlay = () => {
    const { userRef } = this.props;
    const { timer, modalShow } = this.state;
    window.mixpanel.track('Timer Started', { 'seconds': timer.getDuration() / 1000 });

    timer.start();
    userRef.update({
      'timerData.modalShow': modalShow,
      'timerData.status': 'started',
      'timerData.endingTime': new Date().getTime() + timer.getDuration(),
    });
  }

  hitPause = () => {
    const { userRef } = this.props;
    const { timer } = this.state;
    timer.pause();
    userRef.update({
      'timerData.status': 'paused',
      'timerData.left': timer.getDuration(),
    });
  }

  hitStop = () => {
    const { userRef } = this.props;
    const { timer } = this.state;
    timer.stop();
    userRef.update({ timerData: null });
    this.forceUpdate();
  }

  handleMuterChange = () => {
    const { muted } = this.state;
    this.setState({
      muted: !muted,
    });
  }

  render() {
    const { timer, modalShow, endMessage, muted } = this.state;

    const status = timer.getStatus();

    let body;
    if (status === 'started') body = <ButtonGroupCounting hitPause={this.hitPause} hitStop={this.hitStop} />;
    else if (status === 'paused') body = <ButtonGroupPaused hitPlay={this.hitPlay} hitStop={this.hitStop} />;
    else body = <ButtonGroupNew setTime={this.setTime} />;

    let header;
    if (endMessage) header = endMessage;
    else if ((status === 'started') || (status === 'paused')) {
      header = (
        <span style={{ fontSize: '64px' }}>
          {secondsToFomattedTime(Math.round(timer.getDuration() / 1000))}
        </span>
      );
    } else {
      header = (
        <FormattedMessage
          id="timer.select"
          defaultMessage="Select time"
        />
      );
    }

    return (
      <div>
        <Button
          block
          onClick={this.showModal}
          bsStyle={status === 'started' ? 'info' : 'default'}
        >
          <Glyphicon glyph="hourglass" style={{ marginRight: '5px' }} />
          {
            (status === 'started') || (status === 'paused')
              ? secondsToFomattedTime(Math.round(timer.getDuration() / 1000))
              : (
                <FormattedMessage
                  id="timer.timer"
                  defaultMessage="Timer"
                />
              )
          }
        </Button>

        <Modal
          show={modalShow}
          onHide={this.hideModal}
          animation
        >
          <Modal.Header className="text-center" style={{ height: '100px' }}>
            <h1>
              {header}
            </h1>
          </Modal.Header>
          <Modal.Body className="text-center">
            {body}
            <div style={{ marginTop: "2em" }}>
              <label className="checkbox-inline">
                <input type="checkbox" value="" checked={!muted} onChange={this.handleMuterChange} />
                <FormattedMessage
                  id="timer.playSound"
                  defaultMessage="Play sound"
                />
              </label>
            </div>
          </Modal.Body>
        </Modal>

        <audio id="timerSound" autoPlay={false} muted={muted}>
          <source src={timerSound} type="audio/mpeg" />
        </audio>
      </div>
    );
  }
}

LessonTimer.propTypes = {
  userRef: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  userRef: state.user.ref,
});

export default connect(mapStateToProps, null)(LessonTimer);
