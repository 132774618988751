import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button, ButtonGroup } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import FontAwesome from 'react-fontawesome';
import {
  FINISH_GROUP_WORK, SHOW_MODAL, HIDE_MODAL, SETUP_GROUPS,
} from '../../reducers/groups';

export const shuffle = (array) => {
  // https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;
  const shuffled = array.slice();

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = shuffled[currentIndex];
    shuffled[currentIndex] = shuffled[randomIndex];
    shuffled[randomIndex] = temporaryValue;
  }

  return shuffled;
};

export const splitArrayIntoNGroups = (array, n) => {
  const shuffled = shuffle([...array]);
  const groups = Array.from(Array(n), () => []);

  shuffled.forEach((elem, index) => {
    groups[index % n].push(elem);
  });

  return groups;
};


class GroupWork extends React.Component {
  componentDidMount() {
    const { userRef, setupGroups, finishGroupWork } = this.props;
    this.unsubscribe = userRef.onSnapshot((doc) => {
      const { groupWorkData } = doc.data();

      if (groupWorkData) {
        const n = Math.max(...Object.values(groupWorkData)) + 1;
        const split = Array.from(Array(n), () => []);
        Object.keys(groupWorkData).forEach((key) => {
          split[groupWorkData[key]].push(key);
        });
        setupGroups(split);
      } else {
        finishGroupWork();
      }
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe && (this.unsubscribe instanceof Function)) this.unsubscribe();
  }

  splitStudentsToGroups = (n) => {
    window.mixpanel.track('Group Work Started', { 'Number Of Groups': n });
    const { userRef, students, hideModal } = this.props;
    const split = splitArrayIntoNGroups(students, n);

    const mapping = {};
    split.forEach((list, elem) => {
      list.forEach((id) => {
        mapping[id] = elem;
      });
    });
    userRef.update({ groupWorkData: mapping });
    hideModal();
  }

  killGroups() {
    const { userRef } = this.props;
    userRef.update({ groupWorkData: null });
  }

  render() {
    const {
      showModal, groupModeIsOn, modalIsOpen, hideModal,
    } = this.props;

    const noGroupsButton = (
      <Button block onClick={showModal}>
        <FontAwesome name="users" size="lg" style={{ marginRight: '5px', color: '#555555' }} />
        <FormattedMessage
          id="lesson.inprogress.groups.start"
          defaultMessage="Group work"
        />
      </Button>
    );

    const groupsButton = (
      <Button block onClick={() => this.killGroups()}>
        <FontAwesome name="users" size="lg" style={{ marginRight: '5px', color: '#555555' }} />

        <FormattedMessage
          id="lesson.inprogress.groups.end"
          defaultMessage="End group work"
        />
      </Button>
    );

    return (

      <div>
        {groupModeIsOn ? groupsButton : noGroupsButton}

        <Modal
          show={modalIsOpen}
          onHide={hideModal}
          animation
        >

          <Modal.Header className="text-center" style={{ height: '100px' }}>
            <h1>
              <FormattedMessage
                id="lesson.inprogress.groups.modal.header"
                defaultMessage="Group work mode"
              />
            </h1>
          </Modal.Header>

          <Modal.Body className="text-center">
            <h3>
              <FormattedMessage
                id="lesson.inprogress.groups.modal.select"
                defaultMessage="Number of groups"
              />
            </h3>
            <ButtonGroup bsSize="large">
              <Button onClick={() => this.splitStudentsToGroups(2)}>
                2
              </Button>
              <Button onClick={() => this.splitStudentsToGroups(3)}>
                3
              </Button>
              <Button onClick={() => this.splitStudentsToGroups(4)}>
                4
              </Button>
              <Button onClick={() => this.splitStudentsToGroups(5)}>
                5
              </Button>
              <Button onClick={() => this.splitStudentsToGroups(6)}>
                6
              </Button>
            </ButtonGroup>

            <p />

            <Button onClick={hideModal} bsStyle="info">
              <FormattedMessage
                id="custom.lesson.inprogress.random.close"
                defaultMessage="Close"
              />
            </Button>

          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

GroupWork.propTypes = {
  students: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,

  modalIsOpen: PropTypes.bool.isRequired,
  groupModeIsOn: PropTypes.bool.isRequired,
  userRef: PropTypes.shape({}).isRequired,

  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  setupGroups: PropTypes.func.isRequired,
  finishGroupWork: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  modalIsOpen: state.groups.modalIsOpen,
  groupModeIsOn: state.groups.groups.length > 0,
  userRef: state.user.ref,
});

const mapDispatchToProps = dispatch => ({
  showModal: () => dispatch({ type: SHOW_MODAL }),
  hideModal: () => dispatch({ type: HIDE_MODAL }),
  setupGroups: groups => dispatch({ type: SETUP_GROUPS, groups }),
  finishGroupWork: () => dispatch({ type: FINISH_GROUP_WORK }),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupWork);
