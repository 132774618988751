import React from 'react';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FormattedMessage } from 'react-intl';

const Footer = () => (
  <div className="my-footer">
    <Navbar style={{
      paddingTop: '26px', marginBottom: '0px', height: '100px', borderWidth: '4px 0 0 0',
    }}
    >
      <div>
        <Navbar.Text>
          <FormattedMessage
            id="footer.name"
            defaultMessage="Zebra Classroom, 2018"
          />
        </Navbar.Text>
        <Navbar.Collapse>
          <Nav pullRight>
            <LinkContainer to="/contact">
              <NavItem>
                <FormattedMessage
                  id="footer.contact"
                  defaultMessage="Contact us"
                />
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/terms">
              <NavItem>
                <FormattedMessage
                  id="footer.terms"
                  defaultMessage="Terms of use"
                />
              </NavItem>
            </LinkContainer>
            <LinkContainer to="/privacy">
              <NavItem>
                <FormattedMessage
                  id="footer.privacy"
                  defaultMessage="Privacy Policy"
                />
              </NavItem>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  </div>
);

export default Footer;
