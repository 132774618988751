import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Glyphicon, Form, InputGroup, FormControl,
} from 'react-bootstrap';


class EditItemNameForm extends React.Component {
  constructor(props) {
    super(props);
    const { initValue } = this.props;
    this.state = {
      value: initValue || '',
    };
  }

  onChange = event => this.setState({ value: event.target.value });

  onClick = () => {
    const { value } = this.state;
    const { onSave } = this.props;
    onSave(value);
  }

  render() {
    const { value } = this.state;
    return (
      <Form onSubmit={(event) => { event.preventDefault(); this.onClick(); }}>
        <InputGroup>
          <FormControl
            type="text"
            value={value}
            maxLength="140"
            onChange={this.onChange}
          />
          <InputGroup.Button>
            <Button onClick={this.onClick} bsStyle="primary" disabled={!value}>
              <Glyphicon glyph="ok" />
            </Button>
          </InputGroup.Button>
        </InputGroup>
      </Form>
    );
  }
}


EditItemNameForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  initValue: PropTypes.string.isRequired,
};

export default EditItemNameForm;
