import React from 'react';
import PropTypes from 'prop-types';
import {
  ListGroup, ListGroupItem,
  ButtonGroup, Button, Glyphicon,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Confirm } from 'react-confirm-bootstrap';

import EditItemNameForm from './EditItemNameForm';
import NewItemForm from './NewItemForm';


class GroupsList extends React.Component {
  state = {
    groupUnderNameEditId: null,
  };

  render() {
    const {
      groups, onUpdateGroupName, selectedGroup, onEditStudentsList, onRemoveGroup, onAddGroup,
    } = this.props;

    const {
      groupUnderNameEditId,
    } = this.state;

    return (
      <div>

        <h4>
          <FormattedMessage
            id="setting.grouplist.header"
            defaultMessage="Here are your groups:"
          />
        </h4>

        <ListGroup>
          {groups.map(group => (group.id === groupUnderNameEditId
            ? (
              <ListGroupItem key={group.id}>
                <EditItemNameForm
                  onSave={(name) => {
                    onUpdateGroupName(group.id, name);
                    this.setState({ groupUnderNameEditId: null });
                  }}
                  initValue={group.name}
                />
              </ListGroupItem>
            )
            : (
              <ListGroupItem
                key={group.id}
                active={selectedGroup && selectedGroup.id === group.id}
                className="clearfix"
              >

                {group.name}

                <ButtonGroup className="pull-right">
                  <Button bsSize="sm" onClick={() => onEditStudentsList(group.id)}>
                    <Glyphicon glyph="user" />
                    &nbsp;
                    <FormattedMessage
                      id="setting.grouplist.students"
                      defaultMessage="Students"
                    />
                  </Button>
                  <Button bsSize="sm" onClick={() => this.setState({ groupUnderNameEditId: group.id })}>
                    <Glyphicon glyph="pencil" />
                  </Button>
                  <Confirm
                    body={(
                      <FormattedMessage
                        id="setting.grouplist.delete.body"
                        defaultMessage="Are you sure you want to delete group {name}?"
                        values={{ name: group.name }}
                      />)}
                    confirmText={(
                      <FormattedMessage
                        id="setting.grouplist.delete.confirmText"
                        defaultMessage="Confirm Delete"
                      />)}
                    title={(
                      <FormattedMessage
                        id="setting.grouplist.delete.title"
                        defaultMessage="Deleting group"
                      />)}
                    cancelText={(
                      <FormattedMessage
                        id="confirm.cancel"
                        defaultMessage="Cancel"
                      />)}
                    onConfirm={() => onRemoveGroup(group.id)}
                  >
                    <Button bsSize="sm" bsStyle="danger">
                      <Glyphicon glyph="trash" />
                    </Button>
                  </Confirm>
                </ButtonGroup>
              </ListGroupItem>
            )))}
        </ListGroup>

        {/* TODO: placeholder is missing because of the translation */}
        <NewItemForm
          onAdd={onAddGroup}
          label={(
            <FormattedMessage
              id="setting.grouplist.new.label"
              defaultMessage="Add new group"
            />)}
        />
      </div>
    );
  }
}

GroupsList.defaultProps = {
  selectedGroup: null,
};

GroupsList.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired, // TODO: Specify
  selectedGroup: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onAddGroup: PropTypes.func.isRequired,
  onUpdateGroupName: PropTypes.func.isRequired,
  onEditStudentsList: PropTypes.func.isRequired,
  onRemoveGroup: PropTypes.func.isRequired,
};

export default GroupsList;
