import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Panel, Button, Glyphicon,
} from 'react-bootstrap';
import { Confirm } from 'react-confirm-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import StudentRowDesktopDemo from './StudentRowDesktopDemo';
import { themeShape } from '../../reducers/themes';


const SingleCustomTheme = ({ removeTheme, theme, last }) => (
  <div>
    <h4>
      <Confirm
        body={(
          <FormattedMessage
            id="settings.customthemes.delete.text"
            defaultMessage="Are you sure you want to delete this theme?"
          />)}
        confirmText={(
          <FormattedMessage
            id="settings.customthemes.delete.confirmText"
            defaultMessage="Confirm"
          />)}
        title={(
          <FormattedMessage
            id="settings.customthemes.delete.title"
            defaultMessage="Deleting the theme"
          />)}
        cancelText={(
          <FormattedMessage
            id="confirm.cancel"
            defaultMessage="Cancel"
          />)}
        onConfirm={removeTheme}
      >
        <Button>
          <Glyphicon glyph="trash" />
        </Button>
      </Confirm>
      {' '}
      <b style={{ marginLeft: '20px' }}>{theme.name}</b>
    </h4>
    <StudentRowDesktopDemo theme={theme} />
    {!last && <hr />}
  </div>
);

SingleCustomTheme.propTypes = {
  removeTheme: PropTypes.func.isRequired,
  theme: PropTypes.shape(themeShape).isRequired,
  last: PropTypes.bool.isRequired,
};


class CustomThemesPanel extends React.Component {
  removeTheme = (themeName) => {
    const { removeCustomeTheme, customThemes, userRef } = this.props;
    removeCustomeTheme(themeName);

    // TODO: Actually delete from the storage

    const updated = customThemes.map(t => t.name).filter(name => name !== themeName);
    userRef.update({ customThemes: updated });
  }

  render() {
    const { customThemes, plus } = this.props;
    const bodyPlus = customThemes.length === 0 ? (
      <p>
        <FormattedMessage
          id="settings.customthemes.maimee"
          defaultMessage="You don't have any themes. Go and create one!"
        />
      </p>
    ) : (
        <div>
          {customThemes.map((theme, index) => (
            <SingleCustomTheme
              key={theme.name}
              theme={theme}
              last={index === customThemes.length - 1}
              removeTheme={() => this.removeTheme(theme.name)}
            />
          ))}
        </div>
      );

    const body = plus ? bodyPlus : (
      <h5 className="text-center">
        <FormattedMessage
          id="settings.addnewtheme.zbc"
          defaultMessage="Custom user themes are available only in {link}"
          values={{
            link: <Link to="/plus">Zebra Classroom Plus.</Link>,
          }}
        />
      </h5>
    );

    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title>
            <FormattedMessage
              id="settings.customthemes.title"
              defaultMessage="Your custom themes"
            />
          </Panel.Title>
        </Panel.Heading>
        <Panel.Body>{body}</Panel.Body>
      </Panel>
    );
  }
}

CustomThemesPanel.propTypes = {
  customThemes: PropTypes.arrayOf(PropTypes.shape(themeShape).isRequired).isRequired,
  removeCustomeTheme: PropTypes.func.isRequired,
  plus: PropTypes.bool.isRequired,
  userRef: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  userRef: state.user.ref,
  // plus: state.user.plus,
  plus: true, // Everyone is plus for now
  customThemes: state.themes
    .availableThemes
    .filter(t => state.user.customThemes.includes(t.name)),
});

const mapDispatchToProps = dispatch => ({
  removeCustomeTheme: themeName => dispatch({ type: 'REMOVE_CUSTOM_THEME', themeName }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomThemesPanel);
