import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Panel,
  Row, Col,
  Image,
  Button, Glyphicon, FormGroup, FormControl,
  ControlLabel,
  Form, Alert,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { storage } from '../../config/firebaseConfig';

import {
  imageStyle,
} from '../LessonViews/InProgress';

const MAX_SIZE = 1024 * 1024; // 1Mb

const LABELS = ['-1', '+1', '+2', '+3', '+4', '+5'];
const POSITIONS = [
  'minus1',
  'plus1',
  'plus2',
  'plus3',
  'plus4',
  'plus5',
];


const Uploader = ({ index, setImageForUpload, src }) => {
  const id = `${index}-fileUpload`;

  const handleImageChange = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onload = () => {
      setImageForUpload(file, reader.result, index);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Col xs={2} className="text-center">
      <FormGroup>
        <ControlLabel htmlFor={id} style={{ cursor: 'pointer' }}>
          <h3>
            <Glyphicon glyph="upload" />
            {' '}
            {LABELS[index]}
          </h3>
          <FormControl
            id={id}
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none' }}
          />
        </ControlLabel>
      </FormGroup>

      <Image src={src} style={imageStyle} />
    </Col>
  );
};

Uploader.defaultProps = {
  src: null,
};

Uploader.propTypes = {
  setImageForUpload: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  src: PropTypes.string,
};


class AddNewThemePanel extends React.Component {
  state = {
    themeName: '',
    files: new Array(6).fill(null), // file is object with {file: and src:}
    uploading: false,
    error: null,
  }

  changeName = event => this.setState({ themeName: event.target.value });

  setImageForUpload = (file, src, index) => {
    const { files } = this.state;
    if (file.size > MAX_SIZE) {
      this.setState({ error: 'File is bigger than 1Mb' });
    } else {
      const newFiles = [
        ...files.slice(0, index),
        { file, src },
        ...files.slice(index + 1),
      ];
      this.setState({ files: newFiles, error: null });
    }
  };

  uploadThemeToStorage = () => {
    // TODO: Should also update list of themes in customTheme pannel from here

    const { userRef, customThemesNames } = this.props;
    const { themeName, files } = this.state;

    const userId = userRef.id;
    const newThemeName = themeName.trim();
    const themeFolder = storage
      .ref()
      .child(`/${userId}/themes/${newThemeName}`);

    if (customThemesNames.includes(newThemeName)) {
      this.setState({
        uploading: false,
        error: 'Theme with such name already exists',
      });
    } else {
      this.setState({ uploading: true });
      const uploadings = POSITIONS.map((positionName, index) => (
        themeFolder
          .child(`${positionName}.png`)
          .put(files[index].file)
      ));

      uploadings.push(
        userRef.update({
          customThemes: [...customThemesNames, newThemeName],
        }),
      );

      Promise.all(uploadings).then(() => {
        this.setState({
          themeName: '',
          files: new Array(6).fill(null),
          uploading: false,
          error: null,
        });
      });
    }
  };

  render() {
    const {
      uploading, themeName, files, error,
    } = this.state;
    const { plus } = this.props;

    const ready = (
      (!uploading)
      && (themeName.trim().length !== 0)
      && (files.filter(Boolean).length === 6)
    );

    const body = !plus
      ? (
        <Panel.Body>
          <h5 className="text-center">
            <FormattedMessage
              id="settings.addnewtheme.zbc"
              defaultMessage="Custom user themes are available only in {link}"
              values={{
                link: <Link to="/plus">Zebra Classroom Plus.</Link>,
              }}
            />
          </h5>
        </Panel.Body>
      )
      : (
        <Panel.Body>

          {
            error && (
              <Alert bsStyle="danger">
                <FormattedMessage
                  id="settings.addnewtheme.error"
                  defaultMessage="Error: {error}"
                  values={{ error }}
                />
              </Alert>
            )
          }

          <Row>
            <Col xs={12}>
              <Form inline>
                <FormGroup>

                  <ControlLabel>
                    <FormattedMessage
                      id="settings.addnewtheme.entername"
                      defaultMessage="Enter name for your new theme:"
                    />
                  </ControlLabel>
                  {' '}
                  <FormControl
                    type="text"
                    maxLength="80"
                    width="120"
                    value={themeName}
                    onChange={this.changeName}
                    required
                  />
                </FormGroup>
              </Form>
            </Col>
          </Row>

          <hr />

          <Row>
            {
              [-1, 1, 2, 3, 4, 5].map((score, index) => (
                <Uploader
                  key={`uploader-score-${score}`}
                  src={files[index] && files[index].src}
                  position={score}
                  index={index}
                  setImageForUpload={this.setImageForUpload}
                />
              ))
            }
          </Row>

          <hr />

          <Row>
            <Col xs={12} md={8}>
              <FormattedMessage
                id="settings.addnewtheme.bottom"
                defaultMessage="Here you can uplaod images for your new theme. We support all image formats, but every file should be less than 1Mb."
              />
            </Col>

            <Col xs={12} md={4} className="text-right">
              <Button
                onClick={this.uploadThemeToStorage}
                bsStyle="success"
                disabled={!ready}
              >
                {
                  uploading
                    ? (
                      <FormattedMessage
                        id="settings.addnewtheme.button.uploading"
                        defaultMessage="Uploading..."
                      />
                    )
                    : (
                      <FormattedMessage
                        id="settings.addnewtheme.button.create"
                        defaultMessage="Create theme"
                      />
                    )
                }
              </Button>
            </Col>
          </Row>
        </Panel.Body>
      );

    return (
      <Panel>
        <Panel.Heading>
          <Panel.Title>
            <FormattedMessage
              id="settings.addnewtheme.title"
              defaultMessage="Add new custom theme"
            />
          </Panel.Title>
        </Panel.Heading>
        {body}
      </Panel>
    );
  }
}

AddNewThemePanel.propTypes = {
  userRef: PropTypes.shape({}).isRequired,
  plus: PropTypes.bool.isRequired,
  customThemesNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

const mapStateToProps = state => ({
  userRef: state.user.ref,
  // plus: state.user.plus,
  plus: true, // Everyone is plus for now
  customThemesNames: state.user.customThemes,
});

export default connect(mapStateToProps, null)(AddNewThemePanel);
