import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Grid, Row, Col, Panel,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { firestore } from '../../config/firebaseConfig';

import MatchingTableSettingPanel from './MatchingTableSettingPanel';
import GroupsList from './GroupsList';
import StudentsList from './StudentsList';
import CustomThemesPanel from './CustomThemesPanel';
import AddNewThemePanel from './AddNewThemePanel';


class Settings extends React.Component {
  state = {
    loading: true,
    groups: [],
    groupUnderEdit: null,
    students: [],
    error: null,
  };

  componentDidMount() {
    const { userRef } = this.props;
    this.unsubscribe1 = userRef
      .collection('groups').orderBy('name')
      .onSnapshot((res) => {
        const groups = [];
        res.forEach((doc) => {
          groups.push({
            id: doc.id,
            name: doc.data().name,
          });
        });
        this.setState({
          loading: false,
          groups,
        });
      });
  }

  componentWillUnmount() {
    if (this.unsubscribe1 && (this.unsubscribe1 instanceof Function)) this.unsubscribe1();
    if (this.unsubscribe2 && (this.unsubscribe2 instanceof Function)) this.unsubscribe2();
  }

  closeStudentsEditList = () => {
    this.setState({
      students: [],
      groupUnderEdit: null,
    });
  };

  onAddGroup = (name) => {
    window.mixpanel.track('Group Added', { 'Group Name': name });
    const { userRef } = this.props;
    const newName = name.trim();
    userRef
      .collection('groups').add({ name: newName })
      .then(this.setState({ error: '' }))
      .catch(error => this.setState({ error }));
  };

  onUpdateGroupName = (groupId, name) => {
    const newName = name.trim();
    const { userRef } = this.props;
    const { groupUnderEdit } = this.state;
    userRef.collection('groups').doc(groupId)
      .update({ name: newName })
      .then(() => {
        if (groupUnderEdit && (groupUnderEdit.id === groupId)) {
          this.setState({ groupUnderEdit: { name: newName, id: groupId } });
        }
      });
  };

  onRemoveGroup = (groupId) => {
    const { userRef } = this.props;
    const { groupUnderEdit } = this.state;
    const group = userRef.collection('groups').doc(groupId);

    group.collection('students').get()
      .then((res) => {
        const batch = firestore.batch();
        res.forEach(doc => batch.delete(group.collection('students').doc(doc.id)));
        batch.delete(group);
        batch.commit().then(() => {
          // Close window if deleting open group
          if (groupUnderEdit && (groupUnderEdit.id === groupId)) {
            this.closeStudentsEditList();
          }
        });
      });
  };

  onEditStudentsList = (groupId) => {
    const { userRef } = this.props;
    const { groups } = this.state;
    this.unsubscribe2 = userRef
      .collection('groups').doc(groupId)
      .collection('students').orderBy('name')
      .onSnapshot((res) => {
        const students = [];
        res.forEach((doc) => {
          students.push({
            id: doc.id,
            name: doc.data().name,
          });
        });
        this.setState({
          students,
          groupUnderEdit: groups.find(group => group.id === groupId),
        });
      });
  };

  onAddStudents = (studentsToUpload) => {
    const { userRef } = this.props;
    const { groupUnderEdit } = this.state;
    const batch = firestore.batch();

    studentsToUpload
      .map(line => line.trim())
      .filter(line => line)
      .forEach((line) => {
        const ref = userRef
          .collection('groups').doc(groupUnderEdit.id)
          .collection('students').doc();
        batch.set(ref, { name: line });
      });

    batch.commit().then(this.setState({ error: '' }))
      .catch(error => this.setState({ error }));
  };

  onUpdateStudentName = (studentId, name) => {
    const { userRef } = this.props;
    const { groupUnderEdit } = this.state;
    userRef.collection('groups').doc(groupUnderEdit.id)
      .collection('students').doc(studentId)
      .update({ name })
      .then(this.setState({ error: '' }))
      .catch(error => this.setState({ error }));
  };

  onRemoveStudent = (studentId) => {
    const { userRef } = this.props;
    const { groupUnderEdit } = this.state;
    userRef.collection('groups').doc(groupUnderEdit.id)
      .collection('students').doc(studentId)
      .delete()
      .then(this.setState({ error: '' }))
      .catch(error => this.setState({ error }));
  };

  render() {
    const {
      loading, error, groups, groupUnderEdit, students,
    } = this.state;

    const helmet = (
      <Helmet>
        <title>Zebra Classroom - Settings</title>
      </Helmet>
    );

    if (loading) {
      return (
        <div>
          {helmet}
          <Grid>
            <Row>
              <Col xs={12}>
                <h3>
                  <FormattedMessage
                    id="setting.loading"
                    defaultMessage="Loading..."
                  />
                </h3>
              </Col>
            </Row>
          </Grid>
        </div>
      );
    }
    return (
      <div>
        {helmet}
        <Grid>
          {
            error
            && (
              <Row>
                <Col xs={12}>
                  <Panel bsStyle="danger">
                    <Panel.Heading>
                      <Panel.Title>
                        <FormattedMessage
                          id="setting.error"
                          defaultMessage="Error"
                        />
                      </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                      {/* TODO: Error are not translated */}
                      {error}
                    </Panel.Body>
                  </Panel>
                </Col>
              </Row>
            )
          }

          <Panel style={{ marginBottom: '30px' }}>
            <Panel.Heading>
              <Panel.Title>
                <FormattedMessage
                  id="setting.header"
                  defaultMessage="Groups and students"
                />
              </Panel.Title>
            </Panel.Heading>
            <Panel.Body>
              <Row>
                <Col xs={12} sm={6}>
                  <GroupsList
                    groups={groups}
                    selectedGroup={groupUnderEdit}
                    onAddGroup={this.onAddGroup}
                    onUpdateGroupName={this.onUpdateGroupName}
                    onEditStudentsList={this.onEditStudentsList}
                    onRemoveGroup={this.onRemoveGroup}
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <StudentsList
                    group={groupUnderEdit}
                    students={students}
                    onClose={this.closeStudentsEditList}
                    onAddStudents={this.onAddStudents}
                    onUpdateStudentName={this.onUpdateStudentName}
                    onRemoveStudent={this.onRemoveStudent}
                  />
                </Col>
              </Row>
            </Panel.Body>
          </Panel>

          <MatchingTableSettingPanel />

          <AddNewThemePanel />

          <CustomThemesPanel />

        </Grid>
      </div>
    );
  }
}

Settings.propTypes = {
  userRef: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  userRef: state.user.ref,
});

export default connect(mapStateToProps, null)(Settings);
