import ReactPixel from 'react-facebook-pixel';

const advancedMatching = {};
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init('172375436925107', advancedMatching, options);

export default ReactPixel;
