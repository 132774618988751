import React from 'react';
import { Modal, Button, Glyphicon } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Timer from 'timer.js';
import {
  secondsToFomattedTime,
  ButtonGroupNew,
  ButtonGroupPaused,
  ButtonGroupCounting,
} from './LessonTimer';
import timerSound from '../../timerSound.mp3';


class LessonTimerLocal extends React.Component {
  timerConfig = {
    tick: 1,
    ontick: () => this.forceUpdate(),
    onstart: () => this.setState({ endMessage: null }),
    onpause: () => this.forceUpdate(),
    onend: () => {
      var sound = document.getElementById("timerSound");
      sound && sound.play();  // Maybe just select and play
      this.setState({
        endMessage: <FormattedMessage
          id="timer.timeisup"
          defaultMessage="Time's up!"
        />,
      });
    },
  }

  state = {
    modalShow: false,
    endMessage: null,
    timer: new Timer(this.timerConfig),
    muted: true,
  }

  componentWillUnmount() {
    const { timer } = this.state;
    timer.off('all');
  }

  showModal = () => {
    this.setState({ modalShow: true, endMessage: null });
  }

  hideModal = () => {
    this.setState({ modalShow: false, endMessage: null });
  }

  setTime = (seconds) => {
    const { timer } = this.state;
    timer.start(seconds);
  }

  hitPlay = () => {
    const { timer } = this.state;
    timer.start();
  }

  hitPause = () => {
    const { timer } = this.state;
    timer.pause();
  }

  hitStop = () => {
    const { timer } = this.state;
    timer.stop();
    this.forceUpdate();
  }

  handleMuterChange = () => {
    const { muted } = this.state;
    this.setState({
      muted: !muted,
    });
  }

  render() {
    const { timer, modalShow, endMessage, muted } = this.state;
    const status = timer.getStatus();

    let body;
    if (status === 'started') body = <ButtonGroupCounting hitPause={this.hitPause} hitStop={this.hitStop} />;
    else if (status === 'paused') body = <ButtonGroupPaused hitPlay={this.hitPlay} hitStop={this.hitStop} />;
    else body = <ButtonGroupNew setTime={this.setTime} />;

    let header;
    if (endMessage) header = endMessage;
    else if ((status === 'started') || (status === 'paused')) {
      header = (
        <span style={{ fontSize: '64px' }}>
          {secondsToFomattedTime(Math.round(timer.getDuration() / 1000))}
        </span>
      );
    } else {
      header = (
        <FormattedMessage
          id="timer.select"
          defaultMessage="Select time"
        />
      );
    }

    return (
      <div>
        <Button
          block
          onClick={this.showModal}
          bsStyle={status === 'started' ? 'info' : 'default'}
        >
          <Glyphicon glyph="hourglass" style={{ marginRight: '5px' }} />
          {
            (status === 'started') || (status === 'paused')
              ? secondsToFomattedTime(Math.round(timer.getDuration() / 1000))
              : (
                <FormattedMessage
                  id="timer.timer"
                  defaultMessage="Timer"
                />
              )
          }
        </Button>

        <Modal
          show={modalShow}
          onHide={this.hideModal}
          animation
        >
          <Modal.Header className="text-center" style={{ height: '100px' }}>
            <h1>
              {header}
            </h1>
          </Modal.Header>
          <Modal.Body className="text-center">
            {body}
            <div style={{ marginTop: "2em" }}>
              <label className="checkbox-inline">
                <input type="checkbox" value="" checked={!muted} onChange={this.handleMuterChange} />
                <FormattedMessage
                  id="timer.playSound"
                  defaultMessage="Play sound"
                />
              </label>
            </div>
          </Modal.Body>
        </Modal>

        <audio id="timerSound" autoPlay={false} muted={muted}>
          <source src={timerSound} type="audio/mpeg" />
        </audio>
      </div>
    );
  }
}

export default LessonTimerLocal;
