import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, ListGroupItem, ListGroup, Well,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';


const LessonMarkAbsentView = ({
  students, switchStudentAbsent, startLesson, goBack,
}) => (
    <Well>
      <h4>
        <FormattedMessage
          id="lesson.markabsent.header"
          defaultMessage="Who is absent today?"
        />
      </h4>
      <ListGroup>
        {students.map(student => (
          <ListGroupItem
            key={student.id}
            onClick={() => switchStudentAbsent(student.id)}
          >
            <input type="checkbox" value="" checked={student.absent} readOnly={true} />
            <span style={{ marginLeft: "1em" }}>
              {student.name}
            </span>
          </ListGroupItem>
        ))}
      </ListGroup>

      <Button className="pull-right" onClick={() => goBack()}>
        <FormattedMessage
          id="lesson.markabsent.goback"
          defaultMessage="Go Back"
        />
      </Button>

      <Button
        bsStyle="primary"
        disabled={students.every(student => student.absent)}
        onClick={() => startLesson()}
      >
        <FormattedMessage
          id="lesson.markabsent.start"
          defaultMessage="Start lesson"
        />
      </Button>
    </Well>
  );

LessonMarkAbsentView.propTypes = {
  switchStudentAbsent: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  startLesson: PropTypes.func.isRequired,
  students: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    absent: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
};

export default LessonMarkAbsentView;
