import { REMOVE_CUSTOM_THEME } from './themes';

export const SET_USER_DATA = 'SET_USER_DATA';
export const UNSET_USER_DATA = 'UNSET_USER_DATA';
export const SET_MATCHING_TABLE = 'SET_MATCHING_TABLE';
export const SET_PLUS_STATUS = 'SET_PLUS_STATUS';


const defaultState = {
  ref: null,
  loading: false,
  plus: false,
  matchingTable: [],
  customThemes: [],
};


export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return { ...state, ...action.payload, loading: false };

    case SET_MATCHING_TABLE:
      return { ...state, matchingTable: action.payload.matchingTable };

    case REMOVE_CUSTOM_THEME:
      return {
        ...state,
        customThemes: state.customThemes.filter(name => name !== action.themeName),
      };

    case SET_PLUS_STATUS:
      return { ...state, plus: action.payload.plus };

    case UNSET_USER_DATA:
      return { ...defaultState, loading: false };

    default:
      return state;
  }
};
