import PropTypes from 'prop-types';

import SWminus1 from '../images/themes/StarWars/minus1.jpg';
import SWplus1 from '../images/themes/StarWars/plus1.jpg';
import SWplus2 from '../images/themes/StarWars/plus2.jpg';
import SWplus3 from '../images/themes/StarWars/plus3.jpg';
import SWplus4 from '../images/themes/StarWars/plus4.jpg';
import SWplus5 from '../images/themes/StarWars/plus5.jpg';

import Carsminus1 from '../images/themes/Cars/minus1.png';
import Carsplus1 from '../images/themes/Cars/plus1.png';
import Carsplus2 from '../images/themes/Cars/plus2.png';
import Carsplus3 from '../images/themes/Cars/plus3.png';
import Carsplus4 from '../images/themes/Cars/plus4.png';
import Carsplus5 from '../images/themes/Cars/plus5.png';

import EvolutionMinus1 from '../images/themes/Evolution/minus1.png';
import EvolutionPlus1 from '../images/themes/Evolution/plus1.png';
import EvolutionPlus2 from '../images/themes/Evolution/plus2.png';
import EvolutionPlus3 from '../images/themes/Evolution/plus3.png';
import EvolutionPlus4 from '../images/themes/Evolution/plus4.png';
import EvolutionPlus5 from '../images/themes/Evolution/plus5.png';

import AnimalsMinus1 from '../images/themes/Animals/minus1.jpg';
import AnimalsPlus1 from '../images/themes/Animals/plus1.jpg';
import AnimalsPlus2 from '../images/themes/Animals/plus2.jpg';
import AnimalsPlus3 from '../images/themes/Animals/plus3.jpg';
import AnimalsPlus4 from '../images/themes/Animals/plus4.jpg';
import AnimalsPlus5 from '../images/themes/Animals/plus5.jpg';


import InventionsMinus1 from '../images/themes/Inventions/minus1.png';
import InventionsPlus1 from '../images/themes/Inventions/plus1.png';
import InventionsPlus2 from '../images/themes/Inventions/plus2.png';
import InventionsPlus3 from '../images/themes/Inventions/plus3.png';
import InventionsPlus4 from '../images/themes/Inventions/plus4.png';
import InventionsPlus5 from '../images/themes/Inventions/plus5.png';

const ThemeStarWars = {
  name: 'Star Wars',
  circle: true,
  rounded: false,
  minus1: SWminus1,
  plus1: SWplus1,
  plus2: SWplus2,
  plus3: SWplus3,
  plus4: SWplus4,
  plus5: SWplus5,
};

const ThemeCars = {
  name: 'Cars',
  circle: false,
  rounded: false,
  minus1: Carsminus1,
  plus1: Carsplus1,
  plus2: Carsplus2,
  plus3: Carsplus3,
  plus4: Carsplus4,
  plus5: Carsplus5,
};

const ThemeEvolution = {
  name: 'Evolution',
  circle: false,
  rounded: false,
  minus1: EvolutionMinus1,
  plus1: EvolutionPlus1,
  plus2: EvolutionPlus2,
  plus3: EvolutionPlus3,
  plus4: EvolutionPlus4,
  plus5: EvolutionPlus5,
};

const ThemeAnimals = {
  name: 'Animals',
  circle: true,
  rounded: false,
  minus1: AnimalsMinus1,
  plus1: AnimalsPlus1,
  plus2: AnimalsPlus2,
  plus3: AnimalsPlus3,
  plus4: AnimalsPlus4,
  plus5: AnimalsPlus5,
};

const ThemeInventions = {
  name: 'Inventions',
  circle: false,
  rounded: true,
  minus1: InventionsMinus1,
  plus1: InventionsPlus1,
  plus2: InventionsPlus2,
  plus3: InventionsPlus3,
  plus4: InventionsPlus4,
  plus5: InventionsPlus5,
};


export const availableThemes = [
  ThemeAnimals,
  ThemeEvolution,
  ThemeStarWars,
  ThemeCars,
  ThemeInventions,
];

const themeFromCookie = availableThemes
  .find(theme => theme.name === localStorage.getItem('selectedTheme'));
const defaultTheme = themeFromCookie || availableThemes[0];

const defaultState = {
  selectedTheme: defaultTheme,
  availableThemes,
  customThemesAreLoaded: false,
};

export const SWITCH_SELECTED_THEME = 'SWITCH_SELECTED_THEME';
export const ADD_CUSTOM_THEMES = 'ADD_CUSTOM_THEMES';
export const REMOVE_CUSTOM_THEME = 'REMOVE_CUSTOM_THEME';

export const themeShape = {
  name: PropTypes.string.isRequired,
  circle: PropTypes.bool.isRequired,
  rounded: PropTypes.bool.isRequired,
  minus1: PropTypes.string.isRequired,
  plus1: PropTypes.string.isRequired,
  plus2: PropTypes.string.isRequired,
  plus3: PropTypes.string.isRequired,
  plus4: PropTypes.string.isRequired,
  plus5: PropTypes.string.isRequired,
};

export default (state = defaultState, action) => {
  const selectedTheme = state.availableThemes
    .find(theme => theme.name === action.name) || defaultTheme;

  switch (action.type) {
    case SWITCH_SELECTED_THEME:
      localStorage.setItem('selectedTheme', selectedTheme.name);
      return { ...state, selectedTheme };

    case ADD_CUSTOM_THEMES:
      return {
        ...state,
        availableThemes: [
          ...state.availableThemes,
          ...action.themes,
        ],
        customThemesAreLoaded: true,
      };

    case REMOVE_CUSTOM_THEME:
      return {
        ...state,
        selectedTheme: (state.selectedTheme.name === action.themeName)
          ? defaultTheme : { ...state.selectedTheme },
        availableThemes: state.availableThemes.filter(name => name !== action.themeName),
      };

    default:
      return state;
  }
};
