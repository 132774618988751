import React from 'react';
import { Helmet } from 'react-helmet';
import { FirebaseAuth } from 'react-firebaseui';
import { Grid, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { firebaseAuth, signInOptions } from '../config/firebaseConfig';


const uiConfig = {
  signInSuccessUrl: '/lesson',
  signInOptions,
  callbacks: {
    signInSuccessWithAuthResult: (authResult) => {
      const { additionalUserInfo, user } = authResult;

      if (additionalUserInfo.isNewUser) {
        window.mixpanel.alias(user.uid);
        window.mixpanel.identify(user.uid);
        window.mixpanel.people.set({
          '$email': user.email,
          '$phone': user.phoneNumber,
          '$name': user.displayName,
          '$created': user.metadata.creationTime,
        });
      } else {
        window.mixpanel.identify(user.uid);
      }
      return true;
    }
  }
};

const Login = () => (
  <div>
    <Helmet>
      <title>Zebra Classroom - Sign in / sign up</title>
    </Helmet>
    <Grid>
      <Row className="text-center">
        <Col xs={12} md={4} mdOffset={4}>
          <h3>
            <FormattedMessage
              id="login.header"
              defaultMessage="Signin"
            />
          </h3>
          <FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebaseAuth} />
          <p>
            <FormattedMessage
              id="login.disclaimer"
              defaultMessage="By registering, you agree to our {terms_link}. Learn how we collect data and how we use cookies in our {privacy_link}."
              values={{
                terms_link: (
                  <Link to="/terms">
                    <FormattedMessage
                      id="login.disclaimer.terms"
                      defaultMessage="Terms of Use"
                    />
                  </Link>
                ),
                privacy_link: (
                  <Link to="/privacy">
                    <FormattedMessage
                      id="login.disclaimer.privacy"
                      defaultMessage="Privacy Policy"
                    />
                  </Link>
                ),
              }}
            />
          </p>
        </Col>
      </Row>
    </Grid>
  </div>
);

export default Login;
