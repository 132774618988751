import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import {
  Grid, Row, Col, PanelGroup, Panel,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';


const FAQblock = ({ question, answer }) => (
  <Panel>
    <Panel.Heading>
      <Panel.Title toggle>
        {question}
      </Panel.Title>
    </Panel.Heading>
    <Panel.Collapse>
      <Panel.Body>
        {answer}
      </Panel.Body>
    </Panel.Collapse>
  </Panel>
);

FAQblock.propTypes = {
  question: PropTypes.node.isRequired,
  answer: PropTypes.node.isRequired,
};


const FAQ = () => (
  <div>
    <Helmet>
      <title>Zebra Classroom - FAQ</title>
    </Helmet>

    <Grid>
      <Row>
        <Col xs={12}>
          <p>
            <FormattedMessage
              id="faq.header.text"
              defaultMessage="Here you can find answers to some popular questions.
              If you still have questions, please feel free to contact us {here}."
              values={{
                here: (
                  <Link to="/contact">
                    <FormattedMessage
                      id="faq.here"
                      defaultMessage="here"
                    />
                  </Link>
                ),
              }}
            />
          </p>

          <PanelGroup id="faq">

            <FAQblock
              question={(
                <FormattedMessage
                  id="faq.free.question"
                  defaultMessage="Is Zebra Classroom free to use?"
                />
)}
              answer={(
                <div>
                  <p>
                    <FormattedMessage
                      id="faq.free.answer.p1"
                      defaultMessage="Yes, it is."
                    />
                  </p>
                  {/* <p>
                    <FormattedMessage
                      id="faq.free.answer.p2"
                      defaultMessage=""
                      values={{
                        link: <Link to="/plus">Zebra Classroom Plus</Link>
                      }}
                    />
                  </p> */}
                </div>
)}
            />

            <FAQblock
              question={(
                <FormattedMessage
                  id="faq.upload_list.question"
                  defaultMessage="How to upload student list?"
                />
)}
              answer={(
                <FormattedMessage
                  id="faq.upload_list.answer.p1"
                  defaultMessage="To avoid entering every student by hand you can uplaod {txt} file
                  with the list of students. The file should contain one name per line.
                  You can download an example {link}."
                  values={{
                    txt: <code>.txt</code>,
                    link: (
                      <a target="_blank" href="/example_students_list.txt" download>
                        <FormattedMessage id="faq.here" defaultMessage="here" />
                      </a>
                    ),
                  }}
                />
)}
            />

            <FAQblock
              question={(
                <FormattedMessage
                  id="faq.matching_table.question"
                  defaultMessage="What is a matching table?"
                />
)}
              answer={(
                <FormattedMessage
                  id="faq.matching_table.answer.p1"
                  defaultMessage="After a lesson is finished every student has a score – a number from -1 to 5.
                  However, you can match it to another ranking system (e.g. 2 to 5 in Russia, A to F in the USA).
                  To convert scores to grades automatically you can set up a matching
                  table on {link}."
                  values={{
                    link: (
                      <Link to="/settings">
                        <FormattedMessage id="faq.matching_table.link" defaultMessage="the settings page" />
                      </Link>
                    ),
                  }}
                />
)}
            />

            <FAQblock
              question={(
                <FormattedMessage
                  id="faq.privacy.question"
                  defaultMessage="What is your privacy policy?"
                />
)}
              answer={(
                <FormattedMessage
                  id="faq.privacy.answer.p1"
                  defaultMessage="In few words: we only collect your name and email address and do not share it with anyone.
                  You can read detailed policy {link}."
                  values={{
                    link: (
                      <Link to="/privacy">
                        <FormattedMessage id="faq.here" defaultMessage="here" />
                      </Link>
                    ),
                  }}
                />
)}
            />

            <FAQblock
              question={(
                <FormattedMessage
                  id="faq.name.question"
                  defaultMessage="Why this name, Zebra Classroom?"
                />
)}
              answer={(
                <FormattedMessage
                  id="faq.name.answer.p1"
                  defaultMessage="The difference between a lesson with Zebra Classroom
                  and a usual lesson is the difference between a horse and a zebra.
                  It's still your classroom, your students and your tasks.
                  But now with more awesomeness and style."
                />
)}
            />

          </PanelGroup>
        </Col>
      </Row>
    </Grid>
  </div>
);

export default FAQ;
