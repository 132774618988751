import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { switchLocale } from '../translations';
import en from '../images/flags/en.png';
import ru from '../images/flags/ru.png';
// Icons are from here
// https://www.iconfinder.com/iconsets/142-mini-country-flags-16x16px

const style = { width: '16px' };

const FlagIcon = ({ code }) => {
  const src = code === 'ru' ? ru : en;
  return <img src={src} style={style} alt={`flag-${code}`} />;
};

FlagIcon.propTypes = {
  code: PropTypes.string.isRequired,
};

const LanguageSelector = ({ currentLang, setLanguage }) => (
  <Dropdown
    id="language-selector"
    onSelect={setLanguage}
  >
    <Dropdown.Toggle style={{ width: '60px' }}>
      <FlagIcon code={currentLang} />
    </Dropdown.Toggle>
    <Dropdown.Menu style={{ minWidth: '10px', width: '60px' }}>
      <MenuItem eventKey="en">
        <FlagIcon code="en" />
      </MenuItem>
      <MenuItem eventKey="ru">
        <FlagIcon code="ru" />
      </MenuItem>
    </Dropdown.Menu>
  </Dropdown>
);

LanguageSelector.propTypes = {
  currentLang: PropTypes.string.isRequired,
  setLanguage: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentLang: state.intl.locale,
});

const mapDispatchToProps = dispatch => ({
  setLanguage: locale => dispatch(switchLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
