import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid, Row, Col, Alert,
  Popover, OverlayTrigger, Button,
  Glyphicon,
} from 'react-bootstrap';
import windowSize from 'react-window-size';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { clipScore } from '../Lesson';
import {
  StudentRowMobile,
  StudentRowDesktop,
  StudentRowDesktopCondensed,
  CondensedLayoutToggleButton,
  groupColors,
  decideOnColor, getGroupColor,
} from './InProgress';
import RandomStudentSelectorLocal from './RandomStudentSelectorLocal';
import LessonTimerLocal from './LessonTimerLocal';
import GroupWorkLocal from './GroupWorkLocal';
import ThemeDropdownSelector from './ThemeDropdownSelector';
import { themeShape } from '../../reducers/themes';


const names = [
  'Claude Monet',
  'Edgar Degas',
  'Pablo Picasso',
  'Salvador Dalí',
  'Vincent van Gogh',
  'Édouard Manet',
];


class LessonInProgressViewDemo extends React.Component {
  state = {
    condensedLayout: false,
    students: names.map((name, index) => ({ name, id: `student-${index}`, score: 0 })),
  }

  componentDidMount() {
    window.mixpanel.track('Demo Mode Loaded');
  };

  handleChangeLayout = () => {
    this.setState(prevState => ({ condensedLayout: !prevState.condensedLayout }));
  };

  changeStudentScore = (id, change) => {
    const { students } = this.state;
    const i = students.findIndex(student => student.id === id);
    const newScore = clipScore(students[i].score + change);
    this.setState({
      students: [
        ...students.slice(0, i),
        { ...students[i], score: newScore },
        ...students.slice(i + 1),
      ],
    });
  }

  plusOneForEveyone = () => {
    this.setState(prevState => ({
      students: prevState
        .students
        .map(student => ({ ...student, score: clipScore(student.score + 1) })),
    }));
  };

  changeGroupScore = (group, change) => {
    this.setState(prevState => ({
      students: prevState
        .students
        .map((student) => {
          if (group.includes(student.id)) {
            return { ...student, score: clipScore(student.score + change) };
          }
          return { ...student };
        }),
    }));
  };

  render() {
    const { students, condensedLayout } = this.state;
    const { groups, selectedTheme, windowWidth } = this.props;

    const popoverHoverFocus = (
      <Popover
        id="finish-hover-focus"
        title={(
          <FormattedMessage
            id="demo.youaredemomode"
            defaultMessage="You are in the demo mode!"
          />)}
      >
        <FormattedMessage
          id="demo.buttondescription"
          defaultMessage="You need to login to finish the lesson"
        />
      </Popover>
    );

    const FinishConfirmWindow = (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="top"
        overlay={popoverHoverFocus}
      >
        <Button block bsStyle="primary">
          <FormattedMessage
            id="lesson.inprogress.finish.button"
            defaultMessage="Finish"
          />
        </Button>
      </OverlayTrigger>
    );

    const selector = (
      <RandomStudentSelectorLocal
        changeStudentScore={this.changeStudentScore}
        students={students}
      />
    );

    const topRow = (
      <Row style={{ paddingBottom: '20px' }} className="text-center">
        <Col xs={2}>
          <ThemeDropdownSelector handleThemeChange={this.handleThemeChange} />
        </Col>

        <Col xs={2}>
          <CondensedLayoutToggleButton
            handleChangeLayout={this.handleChangeLayout}
            condensedLayout={condensedLayout}
          />
        </Col>

        <Col xs={2}>
          <LessonTimerLocal />
        </Col>

        <Col xs={2}>
          {selector}
        </Col>

        <Col xs={2} className="text-center">
          <Button onClick={this.plusOneForEveyone} block>
            <FormattedMessage
              id="lesson.inprogress.plusone"
              defaultMessage="+1 for everyone"
            />
          </Button>
        </Col>

        <Col xs={2}>
          <GroupWorkLocal
            students={students.map(student => student.id)}
          />
        </Col>
      </Row>
    );

    const buttonBottomRowMobile = (
      <div>
        <Row style={{ paddingTop: '40px' }}>
          <Col xs={12}>
            {selector}
          </Col>
        </Row>
        <Row style={{ paddingTop: '5px' }}>
          <Col xs={12}>
            <Button block className="pull-right" onClick={this.plusOneForEveyone}>
              <FormattedMessage
                id="lesson.inprogress.plusone"
                defaultMessage="+1 for everyone"
              />
            </Button>
          </Col>
        </Row>
        <Row style={{ paddingTop: '5px' }}>
          <Col xs={12}>
            <LessonTimerLocal />
          </Col>
        </Row>
        <Row style={{ paddingTop: '5px' }}>
          <Col xs={12}>
            <GroupWorkLocal
              students={students.map(student => student.id)}
            />
          </Col>
        </Row>
        <Row style={{ paddingTop: '5px' }}>
          <Col xs={12}>
            {FinishConfirmWindow}
          </Col>
        </Row>
      </div>
    );

    const demoAlert = (
      <Row style={{ paddingBottom: '20px' }} className="text-center">
        <Col xs={12}>
          <Alert bsStyle="info">
            <p style={{ fontSize: '26px' }}>
              <FormattedMessage
                id="demo.youaredemomode"
                defaultMessage="Demo mode!"
              />
            </p>
            {(windowWidth <= 480) && (
              <h4 style={{color: "#fff", marginTop: "0.5rem"}}>
                <FormattedMessage
                  id="demo.description.mobileNote"
                  defaultMessage="You are on mobile, but some features are available only on the desktop version. Check it out!"
                />
              </h4>
            )}
            <p style={{ fontSize: '16px' }}>
              <FormattedMessage
                id="demo.description.p1"
                defaultMessage="Scores are not synced between devices and will not be saved when you leave the page."
              />
            </p>
            <p style={{ fontSize: '16px' }}>
              <FormattedMessage
                id="demo.description.p2"
                defaultMessage="Here you can check our features without registration.
                Try changing scores, lesson themes and students layout.
                {login} to add your students and start a real lesson!"
                values={{
                  login: (
                    <Link to="/login">
                      <FormattedMessage
                        id="demo.description.link.login"
                        defaultMessage="Login here"
                      />
                    </Link>
                  ),
                }}
              />
            </p>
          </Alert>
        </Col>
      </Row>
    );

    const finishRow = (
      <Row style={{ marginTop: '20px' }}>
        <Col xs={2} xsOffset={10}>
          {FinishConfirmWindow}
        </Col>
      </Row>
    );

    const n = groups.length;
    const rowWithGROOUPZ = (n > 0) && (
      <div style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        padding: '30px',
      }}
      >

        {groups.map((group, elem) => (
          <div
            key={`group-with-${group[0]}`}
            style={{
              width: `${100 / n}%`,
              backgroundColor: groupColors[elem],
              padding: '10px',
            }}
            className="text-center"
          >

            <Button onClick={() => this.changeGroupScore(group, -1)}>
              <Glyphicon glyph="minus" />
            </Button>
            {' '}
            <Button onClick={() => this.changeGroupScore(group, +1)}>
              <Glyphicon glyph="plus" />
            </Button>

          </div>
        ))}

      </div>
    );

    const mobileGroupButtons = (n > 0) && (
      groups.map((group, elem) => (
        <Row
          key={`group-with-${group[0]}`}
          className="text-center"
          style={{
            backgroundColor: groupColors[elem],
            margin: '5px 10px 10px 0px',
            padding: '10px',
          }}
        >
          <Col xs={12}>
            <Button onClick={() => this.changeGroupScore(group, -1)}>
              <Glyphicon glyph="minus" />
            </Button>
            {' '}
            <Button onClick={() => this.changeGroupScore(group, +1)}>
              <Glyphicon glyph="plus" />
            </Button>
          </Col>
        </Row>
      ))
    );

    const mobileView = (
      <div>
        {demoAlert}
        {mobileGroupButtons}
        {students
          .slice()
          .sort((a, b) => {
            const groupA = groups.findIndex(group => group.includes(a.id));
            const groupB = groups.findIndex(group => group.includes(b.id));
            return groupA - groupB;
          })
          .map(student => (
            <StudentRowMobile
              key={student.id}
              score={student.score}
              name={student.name}
              changeStudentScore={change => this.changeStudentScore(student.id, change)}
              groupColor={getGroupColor(student.id, groups)}
            />
          ))}
        {buttonBottomRowMobile}
      </div>
    );

    const fullDesktopView = (
      <div>
        {demoAlert}
        {topRow}
        {rowWithGROOUPZ}
        {students
          .slice()
          .sort((a, b) => {
            const groupA = groups.findIndex(group => group.includes(a.id));
            const groupB = groups.findIndex(group => group.includes(b.id));
            return groupA - groupB;
          })
          .map((student, index) => (
            <StudentRowDesktop
              key={student.id}
              score={student.score}
              name={student.name}
              changeStudentScore={change => this.changeStudentScore(student.id, change)}
              theme={selectedTheme}
              backgroundColor={decideOnColor(index, false)}
              groupColor={getGroupColor(student.id, groups)}
            />
          ))}
        {finishRow}
      </div>
    );

    const condensedDesktopView = (
      <div>
        {demoAlert}
        {topRow}
        {rowWithGROOUPZ}
        <Row className="text-center">
          {students
            .slice()
            .sort((a, b) => {
              const groupA = groups.findIndex(group => group.includes(a.id));
              const groupB = groups.findIndex(group => group.includes(b.id));
              return groupA - groupB;
            })
            .map((student, index) => (
              <StudentRowDesktopCondensed
                key={student.id}
                score={student.score}
                name={student.name}
                changeStudentScore={change => this.changeStudentScore(student.id, change)}
                theme={selectedTheme}
                backgroundColor={decideOnColor(index, true)}
                groupColor={getGroupColor(student.id, groups)}
              />
            ))}
        </Row>
        {finishRow}
      </div>
    );

    if (windowWidth <= 480) return <Grid><Row><Col xs={12}>{mobileView}</Col></Row></Grid>;
    if (condensedLayout) return <Grid><Row><Col xs={12}>{condensedDesktopView}</Col></Row></Grid>;
    return <Grid><Row><Col xs={12}>{fullDesktopView}</Col></Row></Grid>;
  }
}


LessonInProgressViewDemo.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
  ).isRequired,
  windowWidth: PropTypes.number.isRequired,
  selectedTheme: PropTypes.shape(themeShape).isRequired,
};

const mapStateToProps = state => ({
  groups: state.groups.groups,
  selectedTheme: state.themes.selectedTheme,
});

const mapDispatchToProps = dispatch => ({
  finishGroupWork: () => dispatch({ type: 'FINISH_GROUP_WORK' }),
});


export default connect(mapStateToProps, mapDispatchToProps)(windowSize(LessonInProgressViewDemo));
