import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Modal, Button, ButtonGroup, Glyphicon,
} from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';


export const selectRandomElement = array => array[Math.floor(Math.random() * array.length)];


export const selectRandomElements = (array, numberOfElements) => {
  const result = new Array(numberOfElements);
  let len = array.length;

  const taken = new Array(len);
  let n = numberOfElements;

  if (n > len) throw new RangeError('selectRandomElements: more elements taken than available');
  n -= 1;
  while (n >= 0) {
    const x = Math.floor(Math.random() * len);
    result[n] = array[x in taken ? taken[x] : x];
    len -= 1;
    taken[x] = len in taken ? taken[len] : len;
    n -= 1;
  }
  return result;
};


class RandomStudentSelector extends React.Component {
  constructor(props) {
    super(props);
    const { students } = this.props;

    this.state = {
      show: false,
      rolling: false,
      selected: '',
      availablePool: students,
    };
  }

  componentDidMount() {
    const { userRef } = this.props;
    this.unsubscribe = userRef.onSnapshot((doc) => {
      const { show } = this.state;
      const { randomSelectorData } = doc.data();

      if (randomSelectorData) {
        this.showTime(
          randomSelectorData.fakeSelected,
          randomSelectorData.realSelected,
        );
      }

      if (show && !randomSelectorData) {
        // Modal was closed on another device
        this.setState({ show: false });
      }
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe && (this.unsubscribe instanceof Function)) this.unsubscribe();
  }

  showTime = (fakeSelected, realSelected) => {
    const { students } = this.props;
    const { availablePool } = this.state;

    const delay = 200;
    fakeSelected.forEach((fake, index) => setTimeout(() => {
      this.setState({ show: true, rolling: true, selected: fake });
    }, delay * index));

    setTimeout(() => {
      const newPool = availablePool.filter(elem => elem.id !== realSelected.id);
      this.setState({
        show: true,
        selected: realSelected,
        rolling: false,
        availablePool: (newPool.length === 0) ? students : newPool,
      });
    }, delay * fakeSelected.length);
  }

  handleSelect = () => {
    window.mixpanel.track('Random Student Selected');
    const { students, userRef } = this.props;
    const { availablePool } = this.state;

    const real = selectRandomElement(availablePool);
    const realSelected = {
      name: real.name,
      id: real.id,
    };

    const studentsToDraw = Math.min(
      Math.floor((Math.random() * 4) + 3), // form 3 to 6
      students.length - 1, // but no more than length of the students
    );
    const fakeSelected = selectRandomElements(
      students.filter(student => student.id !== realSelected.id),
      studentsToDraw,
    ).map(student => ({ name: student.name }));

    this.showTime(fakeSelected, realSelected);

    userRef.update({
      randomSelectorData: {
        fakeSelected,
        realSelected,
      },
    });
  };

  close = () => {
    const { userRef } = this.props;
    this.setState({ show: false, rolling: false });
    userRef.update({ randomSelectorData: null });
  }

  render() {
    const { changeStudentScore } = this.props;
    const { selected, show, rolling } = this.state;

    const selectedHeader = (
      <h1>
        <Button
          bsStyle="danger"
          bsSize="sm"
          style={{ marginRight: '12px' }}
          onClick={() => {
            changeStudentScore(selected.id, -1);
            this.close();
          }}
        >
          <Glyphicon glyph="minus" />
        </Button>

        {selected.name}

        <Button
          bsStyle="success"
          bsSize="sm"
          style={{ marginLeft: '12px' }}
          onClick={() => {
            changeStudentScore(selected.id, +1);
            this.close();
          }}
        >
          <Glyphicon glyph="plus" />
        </Button>
      </h1>
    );

    return (
      <div>
        <Button block onClick={this.handleSelect}>
          <Glyphicon glyph="random" style={{ marginRight: '5px' }} />
          <FormattedMessage
            id="lesson.inprogress.random"
            defaultMessage="Random student"
          />
        </Button>

        <Modal
          show={show}
          onHide={this.close}
          animation
        >
          <Modal.Header className="text-center" style={{ height: '100px' }}>
            {
              rolling
                ? <h3>{selected.name}</h3>
                : selectedHeader
            }
          </Modal.Header>
          <Modal.Body className="text-center">
            <ButtonGroup>
              <Button onClick={this.handleSelect}>
                <FormattedMessage
                  id="custom.lesson.inprogress.random.another"
                  defaultMessage="Another one"
                />
              </Button>
              <Button onClick={this.close} bsStyle="info">
                <FormattedMessage
                  id="custom.lesson.inprogress.random.close"
                  defaultMessage="Close"
                />
              </Button>
            </ButtonGroup>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

RandomStudentSelector.propTypes = {
  userRef: PropTypes.shape({}).isRequired,
  students: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
  changeStudentScore: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
  userRef: state.user.ref,
});

export default connect(mapStateToProps, null)(RandomStudentSelector);
