import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Route, BrowserRouter, Switch, Redirect,
} from 'react-router-dom';
// import windowSize from 'react-window-size';
import { Helmet } from 'react-helmet';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';

import { firestore, firebaseAuth, storage } from './config/firebaseConfig';
import pixel from './config/fb';

import Home from './components/Home';
import FAQ from './components/FAQ';
import Login from './components/Login';
import Settings from './components/Settings/Settings';
import Lesson from './components/Lesson';
import InProgressDemo from './components/LessonViews/InProgressDemo';
import History from './components/History';
import PrivacyPolicy from './components/PrivacyPolicy';
import ContactPage from './components/Contact';
import TermsOfUsePage from './components/TermsOfUsePage';
// import Plus from './components/Plus';
import { NavigationStatic, Navigation } from './components/Navigation';
import Footer from './components/Footer';
// import StoryBehind from './components/StoryBehind';

import { SET_USER_DATA, UNSET_USER_DATA } from './reducers/user';
import { ADD_CUSTOM_THEMES } from './reducers/themes';

import './App.css';


addLocaleData([...en, ...ru]);

const getPromissesForFolder = ref => Promise.all([
  ref.child('minus1.png').getDownloadURL(),
  ref.child('plus1.png').getDownloadURL(),
  ref.child('plus2.png').getDownloadURL(),
  ref.child('plus3.png').getDownloadURL(),
  ref.child('plus4.png').getDownloadURL(),
  ref.child('plus5.png').getDownloadURL(),
]);

const singleHeaderStyle = { marginTop: '200px', textAlign: 'center' };

const defaultMatchingTable = ['😭', '2', '3', '4', '4', '5', 'Excellent! 😎'];
const defaultGroupName = 'IPhO team (this is a deafult class)';
const defaultStudents = [
  'Albert Einstein',
  'Niels Bohr',
  'Erwin Schrödinger',
];

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props => (isLoggedIn
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />)}
  />
);

const helmet = (
  <Helmet>
    <title>Zebra Classroom - Gamified assessment tool</title>
  </Helmet>
);


class App extends React.Component {
  componentDidMount() {
    window.mixpanel.track('App Loaded');
    const { setUserData, unsetUserData } = this.props;


    firebaseAuth.onAuthStateChanged((user) => {
      if (user) {
        window.mixpanel.identify(user.uid);
        window.mixpanel.track('Login');

        const userRef = firestore.collection('users').doc(user.uid);

        // TODO: Maybe it's not smart to ask for a new token on every load
        // user.getIdToken(true).then(() => {
        //   user.getIdTokenResult().then((res) => {
        //     setPlusStatus(!!res.claims.plus);
        //   });
        // });

        userRef.get()
          .then((doc) => {
            if (doc && doc.exists) {
              // User already exists
              const data = doc.data();

              setUserData({
                ref: userRef,
                matchingTable: data.matchingTable,
                customThemes: data.customThemes || [],
              });

              this.loadCustomThemesLinksAndAll(userRef.id, data.customThemes);
            } else {
              // Let's create new user
              const batch = firestore.batch();

              batch.set(userRef, {
                name: user.displayName,
                matchingTable: defaultMatchingTable,
              });

              // Default group and students.
              const defaultGroupRef = userRef.collection('groups').doc();
              batch.set(defaultGroupRef, { name: defaultGroupName });
              defaultStudents.forEach((name) => {
                const ref = defaultGroupRef.collection('students').doc();
                batch.set(ref, { name });
              });

              batch.commit().then(() => {
                setUserData({
                  ref: userRef,
                  matchingTable: defaultMatchingTable,
                  customThemes: [],
                });
              });
            }
          });
      } else {
        unsetUserData();
      }
    });
  }

  loadCustomThemesLinksAndAll(id, customThemesNames) {
    const { addCustomThemes } = this.props;
    const themesPromises = customThemesNames
      .reverse()
      .map((themeName) => {
        const themeFodelrRef = storage
          .ref()
          .child(`/${id}/themes/${themeName}`);
        return getPromissesForFolder(themeFodelrRef);
      });

    Promise.all(themesPromises).then((arrayOfPromisses) => {
      const themes = arrayOfPromisses.map((links, index) => ({
        name: customThemesNames[index],
        circle: false,
        rounded: false,
        minus1: links[0],
        plus1: links[1],
        plus2: links[2],
        plus3: links[3],
        plus4: links[4],
        plus5: links[5],
      }));

      addCustomThemes(themes);
    });
  }


  render() {
    const { loading, isLoggedIn } = this.props;
    if (loading) {
      return (
        <BrowserRouter>
          <div>
            {helmet}
            <NavigationStatic />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/faq" component={FAQ} />
              <Route path="/privacy" component={PrivacyPolicy} />
              <Route path="/contact" component={ContactPage} />
              <Route path="/terms" component={TermsOfUsePage} />
              {/* <Route path="/plus" component={Plus} /> */}
            </Switch>
            <div style={{ padding: '40px' }} />
            <Footer />
          </div>
        </BrowserRouter>
      );
    }
    pixel.pageView();
    return (
      <BrowserRouter>
        <div>
          {helmet}
          <Navigation isLoggedIn={isLoggedIn} />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/login" component={Login} />
            <Route path="/faq" component={FAQ} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/contact" component={ContactPage} />
            <Route path="/terms" component={TermsOfUsePage} />
            <PrivateRoute path="/settings" isLoggedIn={isLoggedIn} component={Settings} />
            {isLoggedIn && <Route path="/lesson" render={() => <Lesson />} />}
            {!isLoggedIn && <Route path="/lesson" render={() => <InProgressDemo />} />}
            <PrivateRoute path="/history" isLoggedIn={isLoggedIn} component={History} />
            {/* <Route path="/plus" component={Plus} /> */}
            <Route render={() => <h1 style={singleHeaderStyle}>404: Not Found</h1>} />
          </Switch>
          <div style={{ padding: '40px' }} />
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  setUserData: PropTypes.func.isRequired,
  unsetUserData: PropTypes.func.isRequired,
  addCustomThemes: PropTypes.func.isRequired,
  // setPlusStatus: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoggedIn: state.user.ref !== null,
  loading: state.user.loading,
  currentLang: state.intl.locale,
});

const mapDispatchToProps = dispatch => ({
  setUserData: payload => dispatch({ type: SET_USER_DATA, payload }),
  unsetUserData: () => dispatch({ type: UNSET_USER_DATA }),
  addCustomThemes: themes => dispatch({ type: ADD_CUSTOM_THEMES, themes }),
  // setPlusStatus: plus => dispatch({ type: SET_PLUS_STATUS, payload: { plus } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
